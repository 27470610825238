import Axios, { AxiosError } from 'axios';

interface OptionsProps {
	useErrorResponse?: boolean;
}

const parseError = (err: AxiosError, options?: OptionsProps) => {
	if (options?.useErrorResponse) {
		if (err?.response?.data) {
			return err.response.data;
		} else {
			return err;
		}
	}

	if (Axios.isCancel(err)) {
		return err.message;
	}

	if (err?.response?.data?.message) {
		return err.response.data.message;
	} else if (err?.response?.data?.status) {
		return err.response.data.status;
	}

	if (err.request) {
		const statusCodeForNoNetwork = 0;

		if (err.request?.status) {
			return err.request?.statusText;
		} else if (err.request?.status === statusCodeForNoNetwork && !window.navigator.onLine)
		/**
		 * request status could equals statusCodeForNoNetwork due to cors error too
		 * hence, the need to verify the online status of the browser
		 */
			return 'Network error';
	}
	return 'Something went wrong!';
};

export default parseError;
