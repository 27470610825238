import { LoginResponse } from "./types";
const refreshAccessToken = async (
  refreshToken: string
): Promise<LoginResponse> => {
  return (
    await fetch(
      `${process.env.REACT_APP_AUTH_API_URL}/v3/login/token?refresh_token=${refreshToken}`
    )
  ).json();
};

const authService = {
  refreshAccessToken,
};

export default authService;
