import React from "react";
import store from "app/redux";
import { Provider } from "react-redux";
import ReactModal from "react-modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Routes from "app/routes";
import { QueryClient, QueryClientProvider } from "react-query";
import ProfileProvider from "./providers/ProfileProvider";
import TokensProvider from "./providers/TokensProvider";

ReactModal.setAppElement("#root");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <TokensProvider>
        <ProfileProvider>
          <Provider store={store}>
            <ToastContainer
              className="toast__container"
              toastClassName="toast__toast"
              bodyClassName="toast__body"
              closeButton={false}
              hideProgressBar={true}
              position="bottom-center"
            />
            <Routes />
          </Provider>
        </ProfileProvider>
      </TokensProvider>
    </QueryClientProvider>
  );
};

export default App;
