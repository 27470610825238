import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import session from "app/lib/session";
import { fetchProfile } from "./types";
import { FetchProfileResponse } from "app/api/profile/types";

function* fetchProfileSaga() {
  try {
    yield put({ type: fetchProfile.pending });

    const res: AxiosResponse<FetchProfileResponse> = yield call(
      api.profileService.fetchProfile
    );
    const data = res.data;
    yield call(session.set, "profile", data);
    yield put({ type: fetchProfile.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchProfile.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([takeLatest(fetchProfile.default, fetchProfileSaga)]);
}
