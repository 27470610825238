import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest, takeEvery } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import {
  fetchAds,
  fetchAd,
  fetchAdProducts,
  createAd,
  editAd,
  setAdStatus,
  FetchAdsAction,
  FetchAdAction,
  CreateAdAction,
  EditAdAction,
  SetAdStatusAction,
  FetchCashConnectAdsAction,
  fetchCashConnectAds,
} from "./types";
import {
  FetchAdsResponse,
  AdType,
  FetchProductsResponse,
  FetchCashConnectAdsResponse,
} from "app/api/ads/types";
import { mapKeys } from "app/utils/helpers";
import { notify } from "app/utils/toast";
import { GenericResponse } from "../../api/types";

function* fetchAdsSaga(action: FetchAdsAction) {
  try {
    yield put({ type: fetchAds.pending });

    const { payload } = action;

    const res: AxiosResponse<FetchAdsResponse> = yield call(
      api.adsService.fetchAds,
      payload
    );
    const { data, meta } = res.data;

    yield put({
      type: fetchAds.fulfilled,
      payload: {
        data: mapKeys(data, "id"),
        meta,
      },
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchAds.rejected, payload: errorMessage });
  }
}

function* fetchCashConnectAdsSaga(action: FetchCashConnectAdsAction) {
  try {
    yield put({ type: fetchAds.pending });

    const { payload } = action;

    const res: AxiosResponse<FetchCashConnectAdsResponse> = yield call(
      api.adsService.fetchCashConnectAds,
      payload
    );
    const { data, meta } = res.data;

    yield put({
      type: fetchCashConnectAds.fulfilled,
      payload: {
        data: mapKeys(data, "id"),
        meta,
      },
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchCashConnectAds.rejected, payload: errorMessage });
  }
}

function* fetchAdSaga(action: FetchAdAction) {
  try {
    yield put({ type: fetchAd.pending });

    const { adId } = action.payload;

    const res: AxiosResponse<AdType> = yield call(api.adsService.fetchAd, adId);
    const { data } = res;
    yield put({
      type: fetchAd.fulfilled,
      payload: data,
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchAd.rejected, payload: errorMessage });
  }
}

function* fetchAdProductsSaga() {
  try {
    yield put({ type: fetchAdProducts.pending });

    const res: AxiosResponse<FetchProductsResponse> = yield call(
      api.adsService.fetchAdProducts
    );
    const { data } = res;
    yield put({
      type: fetchAdProducts.fulfilled,
      payload: mapKeys(data, "code"),
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchAdProducts.rejected, payload: errorMessage });
  }
}

function* createAdSaga(action: CreateAdAction) {
  try {
    yield put({ type: createAd.pending });

    const { payload } = action;

    const res: AxiosResponse<GenericResponse> = yield call(
      api.adsService.createAd,
      payload
    );

    const { data } = res;

    yield call(notify, data.message || "Ad created successfully", {
      variant: "success",
    });
    yield put({
      type: createAd.fulfilled,
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: createAd.rejected, payload: errorMessage });
  }
}

function* editAdSaga(action: EditAdAction) {
  try {
    yield put({ type: editAd.pending });

    const { adId, payload } = action.payload;

    const res: AxiosResponse<GenericResponse> = yield call(
      api.adsService.editAd,
      adId,
      payload
    );

    const { data } = res;

    yield call(notify, data.message || "Ad edited successfully", {
      variant: "success",
    });
    yield put({
      type: editAd.fulfilled,
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: editAd.rejected, payload: errorMessage });
  }
}

function* setAdStatusSaga(action: SetAdStatusAction) {
  try {
    yield put({ type: setAdStatus.pending });

    const { payload } = action;

    const res: AxiosResponse<GenericResponse> = yield call(
      api.adsService.setAdStatus,
      payload
    );

    const { data } = res;

    yield call(notify, data.message || "Ad status updated", {
      variant: "success",
    });
    yield put({
      type: setAdStatus.fulfilled,
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: setAdStatus.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchAds.default, fetchAdsSaga),
    takeLatest(fetchCashConnectAds.default, fetchCashConnectAdsSaga),
    takeEvery(fetchAd.default, fetchAdSaga),
    takeLatest(fetchAdProducts.default, fetchAdProductsSaga),
    takeLatest(createAd.default, createAdSaga),
    takeLatest(editAd.default, editAdSaga),
    takeLatest(setAdStatus.default, setAdStatusSaga),
  ]);
}
