import { lazy } from "react";

const AppNoMatch = lazy(() => import("../views/NoMatch/AppNoMatch"));
const Wallets = lazy(() => import("app/views/Wallets"));
const Prices = lazy(() => import("app/views/Prices"));
const Activity = lazy(() => import("app/views/Activity"));
const Settings = lazy(() => import("app/views/Settings"));
const P2PRoutes = lazy(() => import("app/views/P2P"));
// const CardRoutes = lazy(() => import("app/views/Cards"));

interface AppRoute {
  path: string;
  component: React.ComponentType;
  exact: boolean;
}

const appRoutes: AppRoute[] = [
  {
    path: "/wallets",
    component: Wallets,
    exact: false,
  },
  {
    path: "/prices",
    component: Prices,
    exact: true,
  },
  {
    path: "/activity",
    component: Activity,
    exact: true,
  },
  {
    path: "/settings",
    component: Settings,
    exact: true,
  },
  {
    path: "/p2p",
    component: P2PRoutes,
    exact: false,
  },
  // {
  //   path: "/cards",
  //   component: CardRoutes,
  //   exact: false,
  // },
  {
    path: "*",
    component: AppNoMatch,
    exact: true,
  },
];

export default appRoutes;
