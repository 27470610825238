import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import {
  fetchBanks,
  deleteBankAccount,
  addBankAccount,
  DeleteBankAccountAction,
  FetchBanksAction,
  AddBankAccountAction,
} from "./types";
import {
  FetchBanksResponse,
  AddBankAccountResponse,
  FetchBankAccountsResponse,
} from "app/api/bank/types";
import { fetchBankAccounts } from "./types";
import { notify } from "app/utils/toast";

function* fetchBanksSaga(action: FetchBanksAction) {
  try {
    yield put({ type: fetchBanks.pending });

    const { countryCode } = action.payload;

    const res: AxiosResponse<FetchBanksResponse> = yield call(
      api.bankService.fetchBanks,
      countryCode
    );
    const data = res.data;
    yield put({ type: fetchBanks.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchBanks.rejected, payload: errorMessage });
  }
}

function* fetchBankAccountsSaga() {
  try {
    yield put({ type: fetchBankAccounts.pending });

    const res: AxiosResponse<FetchBankAccountsResponse> = yield call(
      api.bankService.fetchBankAccounts
    );
    const data = res.data;
    yield put({ type: fetchBankAccounts.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchBankAccounts.rejected, payload: errorMessage });
  }
}

function* addBankAccountSaga(action: AddBankAccountAction) {
  try {
    yield put({ type: addBankAccount.pending });

    const { payload } = action;

    const res: AxiosResponse<AddBankAccountResponse> = yield call(
      api.bankService.addBankAccount,
      payload
    );
    const data = res.data;
    yield put({
      type: addBankAccount.fulfilled,
      payload: data,
    });
    yield call(notify, "New bank account added", { variant: "success" });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: addBankAccount.rejected, payload: errorMessage });
  }
}

function* deleteBankAccountSaga(action: DeleteBankAccountAction) {
  try {
    yield put({ type: deleteBankAccount.pending });

    const { bankAccountId } = action.payload;

    yield call(api.bankService.deleteBankAccount, bankAccountId);
    yield put({
      type: deleteBankAccount.fulfilled,
      payload: { bankAccountId },
    });
    yield call(notify, "Bank account deleted", { variant: "success" });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });

    yield put({ type: deleteBankAccount.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchBanks.default, fetchBanksSaga),
    takeLatest(fetchBankAccounts.default, fetchBankAccountsSaga),
    takeLatest(deleteBankAccount.default, deleteBankAccountSaga),
    takeLatest(addBankAccount.default, addBankAccountSaga),
  ]);
}
