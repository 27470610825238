import address from "./address";
import ads from "./ads";
import auth from "./auth";
import bank from "./bank";
import card from "./card";
import deposit from "./deposit";
import order from "./order";
import price from "./price";
import profile from "./profile";
import referral from "./referral";
import market from "./market";
import transaction from "./transaction";
import transfer from "./transfer";
import wallet from "./wallet";
import stories from "./stories";
import trades from "./trades";

const api = {
  addressService: address,
  adsService: ads,
  authService: auth,
  bankService: bank,
  cardService: card,
  depositService: deposit,
  orderService: order,
  priceService: price,
  profileService: profile,
  referralService: referral,
  marketService: market,
  transactionService: transaction,
  transferService: transfer,
  walletService: wallet,
  storiesService: stories,
  tradesService: trades,
};

export default api;
