import React from "react";
import styled from "styled-components";

import useAuth from "app/hooks/useAuth";
import DashboardLayout from "app/styles/blocks/DashboardLayout";
import Sidebar from "./Sidebar";
import Topnav from "./Topnav";
import MobileSidebar from "./Sidebar/Mobile";

import ErrorBoundary from "./ErrorBoundary";
import LoaderContainer from "./LoaderContainer";
import useGetUserProfile from "app/hooks/useGetUserProfile";

const DashboardRoutesWrapper: React.FC = (props) => {
  const isLoggedIn = useAuth();

  const [showMobileSidebar, setShowMobileSidebar] = React.useState(false);

  const openMobileSidebar = () => setShowMobileSidebar(true);
  const closeMobileSidebar = () => setShowMobileSidebar(false);

  const {
    fetchProfile,
    isLoading,
    profile,
    error: errorV2,
  } = useGetUserProfile();

  React.useEffect(() => {
    if (isLoggedIn) {
      if (!profile) {
        fetchProfile();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, profile]);

  // if (status === "fetching" || !userProfile) {
  //   return <StyledLoaderContainer error />;
  // }

  return (
    <StyledLoaderContainer
      loading={isLoading}
      error={!!errorV2}
      errorMessage={errorV2}
      errorControlOnClick={fetchProfile}
    >
      {profile && (
        <DashboardLayout.Main>
          <MobileSidebar
            show={showMobileSidebar}
            onMobileCloseSidebar={closeMobileSidebar}
          />
          <DashboardLayout.Header>
            <Topnav onMobileBtnclicked={openMobileSidebar} />
          </DashboardLayout.Header>

          <DashboardLayout.Body>
            <DashboardLayout.Sidebar>
              <Sidebar />
            </DashboardLayout.Sidebar>
            <DashboardLayout.Content className="dashboard-content">
              <ErrorBoundary location="dashboard">
                {props.children}
              </ErrorBoundary>
            </DashboardLayout.Content>
          </DashboardLayout.Body>
        </DashboardLayout.Main>
      )}
    </StyledLoaderContainer>
  );
};

export default DashboardRoutesWrapper;

const StyledLoaderContainer = styled(LoaderContainer)`
  > div {
    padding: 0px;
  }
`;
