import createActionType from "../util/createActionType";
export interface TokensState {
  refresh_token?: string;
  access_token?: string;
}

export const setTokens = createActionType("set_tokens");

export interface SetTokensAction {
  type: string;
  payload: TokensState;
}
