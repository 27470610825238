import {
  FetchTransactionsPayload,
  DownloadTransactionStatementPayload,
} from "app/api/transaction/types";
import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";

export interface Transaction {
  id: string;
  user_id: string;
  description: string;
  sub_description: string;
  rate: string;
  available_balance: string;
  pending_balance: string;
  hold: string;
  total_balance: string;
  timestamp: string;
  category: string;
  meta: {
    credit: string;
    debit: string;
    fee: string;
    blockchain_url: string;
    confirmations: number;
    required_confirmations: number;
    address: string;
    bank: string;
    rate: string;
  };
  status: string;
  pairs: string[];
  reference: string;
  currency: string;
}

export interface TransactionsSection {
  date: string;
  transactions: Transaction[];
}

export interface TransactionsState {
  data: {
    transactions: Map<string, Map<string, Transaction>>;
    skip_count: number;
  };
  dataStatus: ReducerStatusType;
  actionStatus: ReducerStatusType;
  error: string | null;
}

export const fetchTransactions = createActionType("fetch_transactions");
export const fetchTransaction = createActionType("fetch_transaction");
export const downloadTransactionStatement = createActionType(
  "download_transaction_statement"
);

export interface FetchTransactionsAction {
  type: string;
  payload: FetchTransactionsPayload;
  resetTransactions: boolean;
}

export interface FetchTransactionAction {
  type: string;
  payload: {
    transactionId: string;
  };
}

export interface DownloadTransactionStatementAction {
  type: string;
  payload: DownloadTransactionStatementPayload;
}
