import request from "../request";
import {
	InitializeDepositPayload,
	InitializeDepositResponse,
	FinalizeDepositPayload,
	FinalizeDepositResponse,
	FetchDepositAccountNumberResponse,
	FetchDepositMethodsResponse,
} from "./types";

const initializeDeposit = (payload: InitializeDepositPayload) => {
	return request.post<InitializeDepositResponse>(`/v1.1/deposits`, payload);
};

const finalizeDeposit = (payload: FinalizeDepositPayload) => {
	return request.post<FinalizeDepositResponse>(`/v1.1/deposits/finalize`, payload);
};

const fetchDepositAccountNumber = () => {
	return request.get<FetchDepositAccountNumberResponse>(`/v1.1/deposits/account`);
};

const fetchDepositMethods = (currencyCode: string) => {
	return request.get<FetchDepositMethodsResponse>(`/v1.1/deposits/${currencyCode.toLowerCase()}`);
};

const depositService = {
  initializeDeposit,
  finalizeDeposit,
  fetchDepositAccountNumber,
  fetchDepositMethods,
};

export default depositService;
