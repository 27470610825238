import * as React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import ErrorBoundary from "app/components/ErrorBoundary";
import LoaderContainer from "app/components/LoaderContainer";
import AuthRoutesWrapper from "app/components/AuthRoutesWrapper";
import DashboardRoutesWrapper from "app/components/DashboardRoutesWrapper";
import NoMatch from "app/views/NoMatch";
import Oauth from "../components/Oauth";

import appRoutes from "./app";

const Routes: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <ErrorBoundary>
        <Switch>
          <Redirect from="/" to="/wallets" exact />
          <Route path="/oauth/:token" component={Oauth} exact />
          <Route path="/not-found" component={NoMatch} exact />
          <AuthRoutesWrapper>
            <Switch>
              {/* authenticated routes without dashboard layout */}
              <DashboardRoutesWrapper>
                <React.Suspense fallback={<LoaderContainer loading />}>
                  <Switch>
                    {/* authenticated routes with dashboard layout */}
                    {appRoutes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        component={route.component}
                        exact={route.exact}
                      />
                    ))}
                  </Switch>
                </React.Suspense>
              </DashboardRoutesWrapper>
            </Switch>
          </AuthRoutesWrapper>
        </Switch>
      </ErrorBoundary>
    </Router>
  );
};

export default Routes;

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    const dashboardContent = document.querySelector(".dashboard-content");
    if (dashboardContent) {
      dashboardContent.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
