import { TOKEN_STORAGE_KEY } from "app/constants/variables";
import useUpdateEffect from "app/hooks/useUpdateEffect";
import storage from "app/lib/storage";
import React from "react";
import { DispatchAction } from "./types";

export interface TokenContextType {
  refresh_token?: string;
  access_token?: string;
}

const INITIAL_STATE: TokenContextType = {};

export const TokensContext = React.createContext<TokenContextType>({});

function initToken(initialState: TokenContextType) {
  return { ...initialState, ...storage.get(TOKEN_STORAGE_KEY) };
}

export const TokenActions = {
  setTokens: "SET_TOKENS",
};

function reducer(
  state: TokenContextType,
  action: DispatchAction<TokenContextType>
) {
  switch (action.type) {
    case TokenActions.setTokens:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const TokenDispatch = React.createContext<
  React.Dispatch<DispatchAction<TokenContextType>>
>(() => {});

const TokensProvider: React.FC = (props) => {
  const [tokens, dispatch] = React.useReducer(
    reducer,
    INITIAL_STATE,
    initToken
  );

  useUpdateEffect(() => {
    storage.set(TOKEN_STORAGE_KEY, tokens);
  }, [tokens]);

  return (
    <TokenDispatch.Provider value={dispatch}>
      <TokensContext.Provider value={tokens}>
        {props.children}
      </TokensContext.Provider>
    </TokenDispatch.Provider>
  );
};

export default TokensProvider;
