import { FetchStoriesPayload, Story } from 'app/api/stories/types';
import { PaginationMetaType } from "app/api/types";
import { ReducerStatusType } from "../reducers";
import { GenericAction } from "../types";
import createActionType from "../util/createActionType";

export interface StoriesState {
  status: ReducerStatusType;
  error: string | null;
  data: { [id: string]: Story } | null;
  meta: PaginationMetaType | null;
}

export const fetchStories = createActionType('fetch_stories');

export interface FetchStoriesAction extends GenericAction {
	payload: FetchStoriesPayload;
}
