import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import { fetchPrices, FetchPricesAction } from "./types";
import { FetchPricesResponse } from "app/api/price/types";
import { mapKeys } from "app/utils/helpers";

function* fetchPricesSaga(action: FetchPricesAction) {
  try {
    yield put({ type: fetchPrices.pending });

    const { currency } = action.payload;

    const res: AxiosResponse<FetchPricesResponse> = yield call(
      api.priceService.fetchPrices,
      currency
    );
    const data = res.data.data;
    yield put({ type: fetchPrices.fulfilled, payload: mapKeys(data, "base") });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchPrices.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([takeLatest(fetchPrices.default, fetchPricesSaga)]);
}
