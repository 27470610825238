import { all } from "redux-saga/effects";

import addressesSagas from "./addresses/saga";
import adsSagas from "./ads/saga";
import authSagas from "./auth/saga";
import banksSagas from "./banks/saga";
import cardsSagas from "./cards/saga";
import depositsSagas from "./deposits/saga";
import ordersSagas from "./orders/saga";
import pricesSagas from "./prices/saga";
import profileSagas from "./profile/saga";
import referralsSagas from "./referrals/saga";
import marketSagas from "./market/saga";
import tokenSagas from "./tokens/saga";
import transactionsSagas from "./transactions/saga";
import transfersSagas from "./transfers/saga";
import walletsSagas from "./wallets/saga";
import storiesSagas from "./stories/saga";
import tradesSagas from "./trades/saga";

export default function* root() {
  yield all([
    addressesSagas(),
    adsSagas(),
    authSagas(),
    banksSagas(),
    cardsSagas(),
    depositsSagas(),
    ordersSagas(),
    pricesSagas(),
    profileSagas(),
    referralsSagas(),
    marketSagas(),
    tokenSagas(),
    transactionsSagas(),
    transfersSagas(),
    walletsSagas(),
    storiesSagas(),
    tradesSagas(),
  ]);
}
