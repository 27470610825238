import React from "react";
import { Container, Logo, MobileSidebarButton, Section } from "./styles";
import { ReactComponent as LogoImg } from "app/assets/img/logo.svg";
import { ReactComponent as HamburgerIcon } from "app/assets/img/icons/hamburger.svg";
import UserDropdown from "../UserDropdown";
import { Link } from "react-router-dom";
import useGetUserProfile from "app/hooks/useGetUserProfile";

interface Props {
  onMobileBtnclicked: () => void;
}
const Topnav: React.FC<Props> = (props) => {
  const { profile } = useGetUserProfile();
  const fullName = `${profile?.first_name || ""} ${profile?.last_name || ""}`;

  return (
    <Container>
      <Section>
        <MobileSidebarButton onClick={props.onMobileBtnclicked}>
          <HamburgerIcon />
        </MobileSidebarButton>
        <Link to="/wallets">
          <Logo>
            <LogoImg />
          </Logo>
        </Link>
      </Section>
      <UserDropdown
        fullName={fullName}
        avatar={profile?.avatar}
        email={profile?.email}
      />
    </Container>
  );
};

export default Topnav;
