import { isSameDay } from "date-fns";

import { TransactionsState, fetchTransactions, fetchTransaction, downloadTransactionStatement, Transaction } from "./types";

const INITIAL_STATE: TransactionsState = {
  error: null,
  actionStatus: "idle",
  dataStatus: "idle",
  data: {
    transactions: new Map(),
    skip_count: 0,
  },
};

export default function transactions(state = INITIAL_STATE, action: any): TransactionsState {
  switch (action.type) {
    case fetchTransactions.default:
      return {
        ...state,
        data: action.resetTransactions ? INITIAL_STATE.data : state.data,
      };

    case fetchTransactions.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchTransactions.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchTransactions.fulfilled: {
      const newTransactions = action.payload.data as Map<string, Map<string, Transaction>>;

      const transactions = new Map([...Array.from(state.data.transactions.entries()), ...Array.from(newTransactions.entries())]);

      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          transactions,
          skip_count: action.payload.skip_count,
        },
      };
    }

    case fetchTransaction.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchTransaction.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchTransaction.fulfilled: {
      const transaction = action.payload.data as Transaction;
      const transactionsObj = Object.fromEntries(state.data.transactions);
      const sectionKey = Object.keys(transactionsObj).find((key) => isSameDay(new Date(key), new Date(transaction.timestamp)));

      const transactions = new Map([...Array.from(state.data.transactions.entries())]);

      if (sectionKey) {
        const section = state.data.transactions.get(sectionKey);
        transactions.set(sectionKey, new Map([...Array.from(section?.entries() || []), [transaction.id, transaction]]));
      } else {
        transactions.set(transaction.timestamp, new Map([[transaction.id, transaction]]));
      }

      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          transactions,
        },
      };
    }

    case downloadTransactionStatement.pending:
      return {
        ...state,
        actionStatus: "fetching",
      };

    case downloadTransactionStatement.rejected:
      return {
        ...state,
        actionStatus: "error",
      };

    case downloadTransactionStatement.fulfilled:
      return {
        ...state,
        actionStatus: "idle",
      };

    default:
      return state;
  }
}
