import { all, put, call, takeLatest } from "redux-saga/effects";
import { setTokens, SetTokensAction } from "./types";
import storage from "app/lib/storage";

function* setTokensSaga(action: SetTokensAction) {
  const { payload } = action;
  yield call(storage.set, "tokens", payload);
  yield put({ type: setTokens.fulfilled, payload: payload });
}

export default function* allSaga() {
  yield all([takeLatest(setTokens.default, setTokensSaga)]);
}
