import queryString from "query-string";
import request from "../request";
import { GenericResponse } from "../types";
import {
  FetchAdsPayload,
  FetchAdsResponse,
  AdType,
  CreateAdPayload,
  EditAdPayload,
  FetchProductsResponse,
  SetAdStatusPayload,
  FetchCashConnectAdsPayload,
  FetchCashConnectAdsResponse,
  FetchConnectOffersParams,
  FetchConnectOffersResponse,
} from "./types";

const fetchAds = (payload: FetchAdsPayload) => {
  const query = queryString.stringify(payload, {
    arrayFormat: "comma",
    skipEmptyString: true,
    skipNull: true,
  });
  return request.get<FetchAdsResponse>(`/v1.1/ads/patch?${query}`);
};

const fetchCashConnectAds = (payload: FetchCashConnectAdsPayload) => {
  const query = queryString.stringify(payload, {
    skipEmptyString: true,
    skipNull: true,
  });
  return request.get<FetchCashConnectAdsResponse>(`v1.1/ads/cash?${query}`);
};

const fetchAd = (adId: string) => {
  return request.get<AdType>(`/v1.1/ads/patch/${adId}`);
};

const fetchAdProducts = () => {
  return request.get<FetchProductsResponse>(`/v1.1/ads/products`);
};

const createAd = (payload: CreateAdPayload) => {
  return request.post<GenericResponse>(`/v1.1/ads`, payload);
};

const editAd = (adId: string, payload: EditAdPayload) => {
  return request.put<GenericResponse>(`/v1.1/ads/${adId}/edit`, payload);
};

const setAdStatus = ({ adId, status }: SetAdStatusPayload) => {
  return request.put<GenericResponse>(`/v1.1/ads/${adId}/status`, { status });
};

export const fetchConnectOffers = (params: FetchConnectOffersParams) => {
  const qParams = queryString.stringify(
    {
      ...params,
      expand: [
        "payment_accounts.fields",
        "payment_accounts.payment_method",
        "user",
      ],
    },
    { arrayFormat: "none" }
  );

  return request.get<FetchConnectOffersResponse>("/v1/connect/offers/search", {
    baseURL: process.env.REACT_APP_API_GATEWAY_URL,
    params: new URLSearchParams(qParams),
  });
};

const adsService = {
  fetchAds,
  fetchAd,
  fetchAdProducts,
  createAd,
  editAd,
  setAdStatus,
  fetchCashConnectAds,
  fetchConnectOffers,
};

export default adsService;
