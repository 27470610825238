import { AuthState } from "./types";

const INITIAL_STATE: AuthState = {};

export default function auth(state = INITIAL_STATE, action: any): AuthState {
  switch (action.type) {
    default:
      return state;
  }
}
