import { DepositsState, initializeDeposit, finalizeDeposit, fetchDepositAccountNumber, fetchDepositMethods, clearDeposit } from "./types";

const INITIAL_STATE: DepositsState = {
  error: null,
  dataStatus: "idle",
  actionStatus: "idle",
  data: {
    initializationData: null,
    finalizationData: null,
    accountNumber: null,
    depositMethods: [],
  },
};

export default function deposits(state = INITIAL_STATE, action: any): DepositsState {
  switch (action.type) {
    case initializeDeposit.pending:
      return {
        ...state,
        actionStatus: "initializing",
        error: null,
      };

    case initializeDeposit.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case initializeDeposit.fulfilled:
      return {
        ...state,
        dataStatus: "initialized",
        actionStatus: "idle",
        error: null,
        data: {
          ...state.data,
          initializationData: action.payload,
        },
      };

    case finalizeDeposit.pending:
      return {
        ...state,
        actionStatus: "finalizing",
        error: null,
      };

    case finalizeDeposit.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case finalizeDeposit.fulfilled:
      return {
        ...state,
        dataStatus: "finalized",
        actionStatus: "idle",
        error: null,
        data: {
          ...state.data,
          finalizationData: action.payload,
        },
      };

    case fetchDepositAccountNumber.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchDepositAccountNumber.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchDepositAccountNumber.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          accountNumber: action.payload,
        },
      };

    case fetchDepositMethods.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchDepositMethods.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchDepositMethods.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          depositMethods: action.payload,
        },
      };

    case clearDeposit.fulfilled:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
