import { PricesState, fetchPrices } from "./types";

const INITIAL_STATE: PricesState = {
  error: null,
  dataStatus: "idle",
  data: {},
};

export default function prices(
  state = INITIAL_STATE,
  action: any
): PricesState {
  switch (action.type) {
    case fetchPrices.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchPrices.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchPrices.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: action.payload,
      };

    default:
      return state;
  }
}
