import { GenericAction } from "../types";
import {
  createReview,
  createTrade,
  getAllTrades,
  getSingleTrade,
  setTradeStatus,
  summonTrader,
  createDispute,
  TradesState,
  getSingleDispute,
  exportTrades,
} from "./types";

const initialState: TradesState = {
  trades: {},
  createdTrade: null,
  meta: {
    page: 0,
    per_page: 0,
    offset: 0,
    total_entries_size: 0,
    current_entries_size: 0,
    total_pages: 0,
  },
  status: "idle",
  error: null,
  dispute: { status: "idle", error: null, response: undefined },
};

const tradesReducer = (
  state = initialState,
  action: GenericAction
): TradesState => {
  switch (action.type) {
    case getAllTrades.pending:
    case getSingleTrade.pending:
      return {
        ...state,
        status: "fetching",
        error: null,
      };

    case createTrade.pending:
      return {
        ...state,
        status: "creating",
        createdTrade: null,
        error: null,
      };

    case setTradeStatus.pending:
      return { ...state, status: "updating", error: null };

    case getAllTrades.rejected:
    case getSingleTrade.rejected:
    case summonTrader.rejected:
    case createDispute.rejected:
    case createReview.rejected:
    case createTrade.rejected:
    case setTradeStatus.rejected:
    case exportTrades.rejected:
      return { ...state, status: "error", error: action.payload };

    case getAllTrades.fulfilled:
      const { data, meta } = action.payload;
      return {
        ...state,
        status: "fetched",
        error: null,
        trades: data,
        meta: meta,
      };

    case getSingleTrade.fulfilled:
      return {
        ...state,
        status: "fetched",
        error: null,
        trades: { ...state.trades, [action.payload.id]: action.payload },
      };

    case setTradeStatus.fulfilled:
      return {
        ...state,
        status: "updated",
        error: null,
      };

    case createTrade.fulfilled:
      return {
        ...state,
        status: "created",
        createdTrade: action.payload,
        error: null,
      };

    case summonTrader.pending:
      return {
        ...state,
        status: "summoning",
        error: null,
      };

    case summonTrader.fulfilled:
      return {
        ...state,
        status: "summoned",
        error: null,
      };

    case createDispute.pending:
      return {
        ...state,
        status: "disputing",
        error: null,
      };

    case createDispute.fulfilled:
      return {
        ...state,
        status: "disputed",
        error: null,
      };

    case getSingleDispute.pending:
      return {
        ...state,
        dispute: {
          ...state.dispute,
          status: "fetching",
          error: null,
        },
      };
    case getSingleDispute.rejected:
      return {
        ...state,
        dispute: {
          ...state.dispute,
          status: "error",
          error: action.payload,
        },
      };
    case getSingleDispute.fulfilled:
      return {
        ...state,
        dispute: {
          ...state.dispute,
          status: "fetched",
          response: action.payload,
          error: null,
        },
      };

    case createReview.pending:
      return {
        ...state,
        status: "reviewing",
        error: null,
      };

    case createReview.fulfilled:
      return {
        ...state,
        status: "reviewed",
        error: null,
      };

    case exportTrades.pending:
      return {
        ...state,
        status: "exporting",
        error: null,
      };

    case exportTrades.fulfilled:
      return {
        ...state,
        status: "exported",
        error: null,
      };

    default:
      return state;
  }
};

export default tradesReducer;
