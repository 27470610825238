import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import { saveAs } from "file-saver";
import api from "app/api";
import parseError from "app/lib/parseError";
import {
  fetchTransactions,
  fetchTransaction,
  FetchTransactionsAction,
  FetchTransactionAction,
  downloadTransactionStatement,
  DownloadTransactionStatementAction,
} from "./types";
import { FetchTransactionsResponse } from "app/api/transaction/types";
import { createMap } from "app/utils/helpers";
import { notify } from "app/utils/toast";
import { formatDateStr } from "../../utils/helpers";

function* fetchTransactionsSaga(action: FetchTransactionsAction) {
  try {
    yield put({ type: fetchTransactions.pending });

    const { payload } = action;

    const res: AxiosResponse<FetchTransactionsResponse> = yield call(
      api.transactionService.fetchTransactions,
      payload
    );
    const { data, skip_count } = res.data;

    const dataWithMappedTransactions = data
      ? data.map((s) => {
          const { transactions } = s;
          const mappedTransactions = createMap(transactions, "id");
          return { ...s, transactions: mappedTransactions };
        })
      : [];

    const dataWithMappedSectionsAndTransactions = createMap(
      dataWithMappedTransactions,
      "date",
      "transactions"
    );

    yield put({
      type: fetchTransactions.fulfilled,
      payload: {
        data: dataWithMappedSectionsAndTransactions,
        skip_count,
      },
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchTransactions.rejected, payload: errorMessage });
  }
}

function* fetchTransactionSaga(action: FetchTransactionAction) {
  try {
    yield put({ type: fetchTransaction.pending });

    const { transactionId } = action.payload;

    const res: AxiosResponse<FetchTransactionsResponse> = yield call(
      api.transactionService.fetchTransaction,
      transactionId
    );
    const { data } = res;
    yield put({
      type: fetchTransaction.fulfilled,
      payload: data,
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchTransaction.rejected, payload: errorMessage });
  }
}

function* downloadTransactionStatementSaga(
  action: DownloadTransactionStatementAction
) {
  try {
    yield put({ type: downloadTransactionStatement.pending });

    const { payload } = action;

    const res: AxiosResponse<Blob> = yield call(
      api.transactionService.downloadTransactionStatement,
      payload
    );

    const { data: file } = res;

    const startDate = formatDateStr(payload.start.toDateString());
    const endDate = formatDateStr(payload.end.toDateString());
    const filename = `Busha ${payload.currency} Account Statement [${startDate}-${endDate}].pdf`;

    yield call(saveAs, file, filename);

    yield put({ type: downloadTransactionStatement.fulfilled });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    const { payload } = action;

    yield call(
      notify,
      `Could not download account statement for ${payload.currency}`,
      {
        variant: "error",
      }
    );
    yield put({
      type: downloadTransactionStatement.rejected,
      payload: errorMessage,
    });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchTransactions.default, fetchTransactionsSaga),
    takeLatest(fetchTransaction.default, fetchTransactionSaga),
    takeLatest(
      downloadTransactionStatement.default,
      downloadTransactionStatementSaga
    ),
  ]);
}
