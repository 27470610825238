import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";
import { FetchDepositMethodsResponse } from "../../api/deposit/types";
import {
  InitializeDepositPayload,
  InitializeDepositResponse,
  FinalizeDepositPayload,
  FinalizeDepositResponse,
  FetchDepositAccountNumberResponse,
} from "app/api/deposit/types";

export interface DepositsState {
  data: {
    initializationData: InitializeDepositResponse | null;
    finalizationData: FinalizeDepositResponse | null;
    accountNumber: FetchDepositAccountNumberResponse | null;
    depositMethods: FetchDepositMethodsResponse;
  };
  dataStatus: ReducerStatusType;
  actionStatus: ReducerStatusType;
  error: string | null;
}

export const initializeDeposit = createActionType("initialize_deposit");
export const finalizeDeposit = createActionType("finalize_deposit");
export const fetchDepositAccountNumber = createActionType("fetch_deposit_account_number");
export const fetchDepositMethods = createActionType("fetch_deposit_methods");
export const clearDeposit = createActionType("clear_deposit");

export interface InitializeDepositAction {
  type: string;
  payload: InitializeDepositPayload;
}

export interface FinalizeDepositAction {
  type: string;
  payload: FinalizeDepositPayload;
}

export interface FetchDepositMethodsAction {
  type: string;
  payload: {
    currencyCode: string;
  };
}

export interface ResendDepositCodeAction {
  type: string;
  payload: {
    token: string;
  };
}
