import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// import "app/assets/styles/index.css";
import App from "app";
import * as serviceWorker from "./serviceWorker";
// POLYFILLS
import "es7-object-polyfill";
import "polyfill-array-includes";
import "object.fromentries/auto";
import "react-dates/initialize";
import GlobalStyles from "app/styles/global";

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: process.env.NODE_ENV === "development" ? 0 : 0.5,
});

ReactDOM.render(
  <React.StrictMode>
    <>
      <GlobalStyles />

      <App />
    </>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
