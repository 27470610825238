import {
  AdsState,
  fetchAds,
  fetchAd,
  fetchAdProducts,
  createAd,
  editAd,
  setAdStatus,
  fetchCashConnectAds,
} from "./types";

const INITIAL_STATE: AdsState = {
  error: null,
  status: "idle",
  data: {
    ads: {
      data: {},
      meta: {
        page: 0,
        per_page: 0,
        offset: 0,
        total_entries_size: 0,
        current_entries_size: 0,
        total_pages: 0,
      },
    },
    cashConnect: {
      data: {},
      meta: {
        page: 0,
        per_page: 0,
        offset: 0,
        total_entries_size: 0,
        current_entries_size: 0,
        total_pages: 0,
      },
    },

    products: {},
  },
};

export default function transactions(
  state = INITIAL_STATE,
  action: any
): AdsState {
  switch (action.type) {
    case fetchAds.pending:
    case fetchCashConnectAds.pending:
      return {
        ...state,
        status: "fetching",
        error: null,
      };

    case fetchAds.rejected:
    case fetchCashConnectAds.rejected:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };

    case fetchAds.fulfilled: {
      const { data, meta } = action.payload;
      return {
        ...state,
        status: "fetched",
        error: null,
        data: {
          ...state.data,
          ads: {
            data,
            meta,
          },
        },
      };
    }

    case fetchCashConnectAds.fulfilled: {
      const { data, meta } = action.payload;
      return {
        ...state,
        status: "fetched",
        error: null,
        data: {
          ...state.data,
          cashConnect: {
            data,
            meta,
          },
        },
      };
    }

    case fetchAd.pending:
      return {
        ...state,
        status: "fetching",
        error: null,
      };

    case fetchAd.rejected:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };

    case fetchAd.fulfilled: {
      return {
        ...state,
        status: "fetched",
        error: null,
        data: {
          ...state.data,
          ads: {
            ...state.data.ads,
            data: {
              ...state.data.ads.data,
              [action.payload.id]: action.payload,
            },
          },
        },
      };
    }

    case fetchAdProducts.pending:
      return {
        ...state,
        status: "fetching",
        error: null,
      };

    case fetchAdProducts.rejected:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };

    case fetchAdProducts.fulfilled: {
      return {
        ...state,
        status: "fetched",
        error: null,
        data: {
          ...state.data,
          products: action.payload,
        },
      };
    }

    case createAd.pending:
      return {
        ...state,
        status: "creating",
        error: null,
      };

    case createAd.rejected:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };

    case createAd.fulfilled: {
      return {
        ...state,
        status: "created",
        error: null,
      };
    }

    case editAd.pending:
    case setAdStatus.pending:
      return {
        ...state,
        status: "updating",
        error: null,
      };

    case editAd.rejected:
    case setAdStatus.rejected:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };

    case editAd.fulfilled:
    case setAdStatus.fulfilled:
      return {
        ...state,
        status: "updated",
        error: null,
      };

    default:
      return state;
  }
}
