import React from "react";
import useTokens from "./useTokens";

export default function useAuth() {
  const tokens = useTokens();

  const [isLoggedIn, setIsLoggedIn] = React.useState(!!tokens?.access_token);

  React.useEffect(() => {
    setIsLoggedIn(!!tokens?.access_token);
  }, [tokens]);

  return isLoggedIn;
}
