import { CreateAddressPayload } from "app/api/address/types";
import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";

export interface Address {
  id: string;
  created_at: string;
  address: string;
  network: string;
  provider: string;
  asset: string;
  label: string;
  memo: string;
  amount_received: number;
}

export interface AddressState {
  data: {
    [key: string]: Address[];
  };
  dataStatus: ReducerStatusType;
  actionStatus: ReducerStatusType;
  error: string | null;
}

export const fetchAddresses = createActionType("fetch_addresses");
export const createAddress = createActionType("create_address");
export const resetAddressAction = createActionType("reset_address_action");
export const clearAddressError = createActionType("clear_address_error");

export interface FetchAddressesAction {
  type: string;
  payload: {
    asset: string;
  };
}

export interface CreateAddressAction {
  type: string;
  payload: CreateAddressPayload;
}
