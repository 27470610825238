import {
  TransfersState,
  initializeTransfer,
  finalizeTransfer,
  resendTransferCode,
  validateDestination,
  fetchWithdrawalRequirements,
  fetchTransferMethods,
  clearTransfer,
  clearTransferError,
} from "./types";

const INITIAL_STATE: TransfersState = {
  error: null,
  dataStatus: "idle",
  actionStatus: "idle",
  data: {
    initializationData: null,
    finalizationData: null,
    validationData: null,
    withdrawalRequirements: {},
    transferMethods: [],
  },
};

export default function transfers(
  state = INITIAL_STATE,
  action: any
): TransfersState {
  switch (action.type) {
    case initializeTransfer.pending:
      return {
        ...state,
        actionStatus: "initializing",
        error: null,
      };

    case initializeTransfer.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case initializeTransfer.fulfilled:
      return {
        ...state,
        dataStatus: "initialized",
        actionStatus: "idle",
        error: null,
        data: {
          ...state.data,
          initializationData: action.payload,
        },
      };

    case finalizeTransfer.pending:
      return {
        ...state,
        actionStatus: "finalizing",
        error: null,
      };

    case finalizeTransfer.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case finalizeTransfer.fulfilled:
      return {
        ...state,
        dataStatus: "finalized",
        actionStatus: "idle",
        error: null,
        data: {
          ...state.data,
          finalizationData: action.payload,
        },
      };

    case validateDestination.pending:
      return {
        ...state,
        actionStatus: "verifying",
        error: null,
      };

    case validateDestination.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case validateDestination.fulfilled:
      return {
        ...state,
        dataStatus: "verified",
        actionStatus: "idle",
        error: null,
        data: {
          ...state.data,
          validationData: action.payload,
        },
      };

    case fetchWithdrawalRequirements.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchWithdrawalRequirements.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchWithdrawalRequirements.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          withdrawalRequirements: action.payload,
        },
      };

    case fetchTransferMethods.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchTransferMethods.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchTransferMethods.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          transferMethods: action.payload,
        },
      };

    case resendTransferCode.pending:
      return {
        ...state,
        actionStatus: "loading",
        error: null,
      };

    case resendTransferCode.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case resendTransferCode.fulfilled:
      return {
        ...state,
        actionStatus: "idle",
        error: null,
      };

    case clearTransfer.fulfilled:
      return INITIAL_STATE;

    case clearTransferError.fulfilled:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}
