import React from "react";
import { Lottie } from "@crello/react-lottie";

import { Container, LoaderBox, ErrorContainer } from "./styles";
import Loader from "../Loader";
import P from "app/styles/elements/P";
import Button from "../Button";

import Animation from "app/assets/animations/warning.json";

interface Props {
  className?: string;
  loading?: boolean;
  error?: boolean;
  errorMessage?: string | null;
  errorControlOnClick?: () => void;
  errorControlClickText?: string;
  loaderContainerClassName?: string;
  loaderBoxSize?: number;
}
const LoaderContainer: React.FC<Props> = (props) => {
  const {
    className,
    loaderBoxSize,
    loading,
    error,
    errorMessage = "Network Error",
    loaderContainerClassName,
    errorControlOnClick,
    errorControlClickText,
  } = props;
  return (
    <Container className={className}>
      {loading && (
        <LoaderBox className={loaderContainerClassName}>
          <Loader size={loaderBoxSize || "60"} />
        </LoaderBox>
      )}
      {!loading && error && (
        <ErrorContainer>
          <Lottie
            config={{ animationData: Animation, loop: false }}
            height="150px"
            speed={1.5}
          />
          <P medium className="error-message">
            {errorMessage}
          </P>
          <Button
            text={errorControlClickText || "Try again"}
            onClick={errorControlOnClick}
            variant="black"
          />
        </ErrorContainer>
      )}
      {!loading && !error && <>{props.children}</>}
    </Container>
  );
};

export default LoaderContainer;
