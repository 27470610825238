const Colors = {
  // Grey
  Grey5: "#F7F9FA",
  Grey10: "#F5F7FA",
  Grey20: "#E4E7EB",
  Grey30: "#CBD2D9",
  Grey40: "#7B8794",
  Grey50: "#9AA5B1",
  Grey50Light: "#F6F6F6",
  Grey50Medium: "#616E7C",
  Grey60: "#3E4C59",
  Grey70: "#1F2933",
  Grey100: "#EEEEEE",
  Grey200: "#E2E2E2",
  Grey300: "#CBCBCB",
  Grey400: "#AFAFAF",
  Grey500: "#757575",
  Grey700: "#333333",
  Grey900: "#141414",

  GreyScale05: "#F7F9FA",
  GreyScale10: "#F0F3F5",

  // Subdued
  Subdued: "#6D7175",

  // Black
  Black100: "#000000",

  // White
  White100: "#ffffff",

  // Green
  Green30: "#31B237",
  Green40: "#18981D",
  Green100: "#18981D",
  Green400: "#21A453",
  Green500: "#09863D",
  Green70: "#12A633",

  // Red
  Red50: "#FBEFEE",
  Red100: "#FFE3E3",
  Red200: "#ECACA5",
  Red400: "#D44333",
  Red600: "#AB1400",

  // Yellow
  Yellow400: "#FFC043",

  // Blue
  Blue400: "#276EF1",
};

export default Colors;
