import session from "./session";
import storage from "./storage";

const LogUserOut = () => {
  session.clear();
  storage.clear();

  window.location.replace(
    `${process.env.REACT_APP_AUTH_APP_URL}/login?redirect=${encodeURIComponent(
      window.location.origin
    )}&logout=true`
  );
};

export default LogUserOut;
