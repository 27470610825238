import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import {
  fetchAddresses,
  createAddress,
  FetchAddressesAction,
  CreateAddressAction,
} from "./types";
import {
  FetchAddressesResponse,
  CreateAddressResponse,
} from "app/api/address/types";
import { notify } from "app/utils/toast";

function* fetchAddressesSaga(action: FetchAddressesAction) {
  try {
    yield put({ type: fetchAddresses.pending });

    const { asset } = action.payload;

    const res: AxiosResponse<FetchAddressesResponse> = yield call(
      api.addressService.fetchAddress,
      asset
    );
    const data = res.data;
    yield put({ type: fetchAddresses.fulfilled, payload: { asset, data } });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchAddresses.rejected, payload: errorMessage });
  }
}

function* createAddressSaga(action: CreateAddressAction) {
  try {
    yield put({ type: createAddress.pending });

    const { payload } = action;

    const res: AxiosResponse<CreateAddressResponse> = yield call(
      api.addressService.createAddress,
      payload
    );
    const data = res.data;
    yield call(notify, `New ${payload.network} address created`, {
      variant: "success",
    });
    yield put({ type: createAddress.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: createAddress.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchAddresses.default, fetchAddressesSaga),
    takeLatest(createAddress.default, createAddressSaga),
  ]);
}
