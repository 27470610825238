import styled, { keyframes, css } from "styled-components";

import Colors from "app/styles/constants/Colors";

export interface LoaderProp {
  size?: string | number;
  color?: string;
  outerColor?: string;
  innerColor?: string;
  stroke?: string | number;
}

const rotate = keyframes`
from {
    transform: rotate(0);
}
to {
    transform: rotate(360deg);
}
`;

export const OuterBox = styled.div<LoaderProp>`
  position: relative;
  width: ${(props) => props.size || 83}px;
  height: ${(props) => props.size || 83}px;
  border-radius: 100%;
  border: ${(props) => props.stroke || 5}px solid ${Colors.Grey30};

  border-top-color: ${Colors.Grey50};
  border-right-color: ${Colors.Grey50};

  animation: ${rotate} 600ms linear infinite;

  ${(props) =>
    props.outerColor &&
    css`
      border-color: ${props.outerColor};
    `};

  ${(props) =>
    props.innerColor &&
    css`
      border-top-color: ${props.innerColor};
      border-right-color: ${props.innerColor};
    `};
  ${(props) =>
    props.color &&
    css`
      border-top-color: ${props.color};
      border-right-color: ${props.color};
    `};
`;

export const InnerBox = styled.div<LoaderProp>`
  display: none;
  position: absolute;
  top: -5px;
  right: -5px;
  width: ${(props) => props.size || 83}px;
  height: ${(props) => props.size || 83}px;
  border-radius: 100%;
  border: 5px solid ${Colors.Grey50};
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: ${rotate} 600ms linear infinite;

  ${(props) =>
    props.innerColor &&
    css`
      border-top-color: ${props.innerColor};
      border-right-color: ${props.innerColor};
    `};
  ${(props) =>
    props.color &&
    css`
      border-top-color: ${props.color};
      border-right-color: ${props.color};
    `};
`;
