import request from "../request";
import {
  ValidateDestinationPayload,
  ValidateDestinationResponse,
  InitializeTransferResponse,
  InitializeTransferPayload,
  FinalizeTransferPayload,
  FinalizeTransferResponse,
  ResendTransferCodeResponse,
  FetchWithdrawalRequirementsResponse,
  FetchTransferMethodsResponse,
  FetchWithdrawalRequirementsResponseV2,
} from "./types";

const API_GATEWAY = process.env.REACT_APP_API_GATEWAY_URL;

const validateDestination = (payload: ValidateDestinationPayload) => {
  return request.get<ValidateDestinationResponse>(`/v1/payout/destination`, {
    params: payload,
    baseURL: API_GATEWAY,
  });
};

const initializeTransfer = (payload: InitializeTransferPayload) => {
  return request.post<InitializeTransferResponse>(
    `/v1/payout/initialize`,
    payload,
    {
      baseURL: API_GATEWAY,
    }
  );
};

const finalizeTransfer = (payload: FinalizeTransferPayload) => {
  return request.post<FinalizeTransferResponse>(
    `/v1/payout/finalize`,
    payload,
    {
      baseURL: API_GATEWAY,
    }
  );
};

const resendTransferCode = (token: string) => {
  return request.post<ResendTransferCodeResponse>(
    `/v1/payout/resend`,
    {
      token,
    },
    {
      baseURL: API_GATEWAY,
    }
  );
};

const fetchWithdrawalRequirementsV2 = (asset: string) => {
  return request.get<FetchWithdrawalRequirementsResponseV2>(
    `/v1/payout/${asset.toLowerCase()}/requirements`,
    {
      baseURL: API_GATEWAY,
    }
  );
};

const fetchWithdrawalRequirements = () => {
  return request.get<FetchWithdrawalRequirementsResponse>(
    `/v1/misc/withdrawal/requirements`
  );
};

const fetchTransferMethods = (currencyCode: string) => {
  return request.get<FetchTransferMethodsResponse>(
    `/v1.1/transfers/${currencyCode.toLowerCase()}`
  );
};

const transferService = {
  validateDestination,
  initializeTransfer,
  finalizeTransfer,
  resendTransferCode,
  fetchWithdrawalRequirements,
  fetchTransferMethods,
  fetchWithdrawalRequirementsV2,
};

export default transferService;
