import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import {
  InitializeDepositAction,
  initializeDeposit,
  FinalizeDepositAction,
  finalizeDeposit,
  fetchDepositMethods,
  FetchDepositMethodsAction,
  fetchDepositAccountNumber,
} from "./types";
import {
  InitializeDepositResponse,
  FinalizeDepositResponse,
  FetchDepositMethodsResponse,
} from "app/api/deposit/types";
import { notify } from "app/utils/toast";

function* initializeDepositSaga(action: InitializeDepositAction) {
  try {
    yield put({ type: initializeDeposit.pending });

    const { payload } = action;

    const res: AxiosResponse<InitializeDepositResponse> = yield call(
      api.depositService.initializeDeposit,
      payload
    );
    const data = res.data;
    yield put({ type: initializeDeposit.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: initializeDeposit.rejected, payload: errorMessage });
  }
}

function* finalizeDepositSaga(action: FinalizeDepositAction) {
  try {
    yield put({ type: finalizeDeposit.pending });

    const { payload } = action;

    const res: AxiosResponse<FinalizeDepositResponse> = yield call(
      api.depositService.finalizeDeposit,
      payload
    );
    const data = res.data;
    yield put({ type: finalizeDeposit.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: finalizeDeposit.rejected, payload: errorMessage });
  }
}

function* fetchDepositMethodsSaga(action: FetchDepositMethodsAction) {
  try {
    yield put({ type: fetchDepositMethods.pending });

    const { currencyCode } = action.payload;

    const res: AxiosResponse<FetchDepositMethodsResponse> = yield call(
      api.depositService.fetchDepositMethods,
      currencyCode
    );
    const { data } = res;
    yield put({ type: fetchDepositMethods.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchDepositMethods.rejected, payload: errorMessage });
  }
}

function* fetchDepositAccountNumberSaga() {
  try {
    yield put({ type: fetchDepositAccountNumber.pending });

    const res: AxiosResponse<FinalizeDepositResponse> = yield call(
      api.depositService.fetchDepositAccountNumber
    );
    const data = res.data;
    yield put({ type: fetchDepositAccountNumber.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({
      type: fetchDepositAccountNumber.rejected,
      payload: errorMessage,
    });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(initializeDeposit.default, initializeDepositSaga),
    takeLatest(finalizeDeposit.default, finalizeDepositSaga),
    takeLatest(
      fetchDepositAccountNumber.default,
      fetchDepositAccountNumberSaga
    ),
    takeLatest(fetchDepositMethods.default, fetchDepositMethodsSaga),
  ]);
}
