import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";
import { WalletPossibleActions } from "app/api/wallet/types";

export interface Wallet {
  id: string;
  currency: string;
  hold: string;
  pending_balance: string;
  balance: string;
  total_balance: string;
  name: string;
  type: string;
  trade: boolean;
  fiat: {
    balance: string;
    currency: string;
    hold: string;
    pending_balance: string;
    total_balance: string;
  };
}

export interface WalletsState {
  data: {
    wallets: { [key: string]: Wallet | null };
    inactiveWallets: string[];
    walletsPossibleActions: { [key: string]: WalletPossibleActions };
  };
  dataStatus: ReducerStatusType;
  actionStatus: ReducerStatusType;
  error: string | null;
}

export const fetchWallets = createActionType("fetch_wallets");
export const fetchWallet = createActionType("fetch_wallet");
export const fetchInactiveWallets = createActionType("fetch_inactive_wallet");
export const createWallet = createActionType("create_wallet");
export const fetchWalletPossibleActions = createActionType(
  "fetch_wallet_possible_actions"
);

export interface FetchWalletAction {
  type: string;
  payload: string;
}

export interface CreateWalletAction {
  type: string;
  payload: {
    wallet: string;
  };
}

export interface FetchWalletPossibleAction {
  type: string;
  payload: {
    walletId: string;
  };
}
