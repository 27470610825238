import styled, { css } from 'styled-components';
import Colors from '../constants/Colors';

interface Props {
  tiny?: boolean;
  little?: boolean;
  small?: boolean;
  medium?: boolean;
  semiBold?: boolean;
  subdued?: boolean;
  midEphasis?: boolean;
  highEphasis?: boolean;
  lowEphasis?: boolean;
  align?: "center" | "left" | "right";
}

const P = styled.p<Props>`
  font-weight: normal;
  font-size: 1rem;

  &.danger {
    color: ${Colors.Red400};
  }

  ${(props) =>
    props.tiny &&
    css`
      /* 12px */
      font-size: 0.6rem;
    `};
  ${(props) =>
    props.little &&
    css`
      /* 14px */
      font-size: 0.7rem;
    `};
  ${(props) =>
    props.small &&
    css`
      /* 16px */
      font-size: 0.8rem;
    `};
  ${(props) =>
    props.medium &&
    css`
      /* 18px */
      font-size: 0.9rem;
    `};
  ${(props) =>
    props.semiBold &&
    css`
      font-weight: 500;
    `};
  ${(props) =>
    props.subdued &&
    css`
      color: #7b8794;
    `};
  ${(props) =>
    props.lowEphasis &&
    css`
      color: rgba(0, 0, 0, 0.38);
    `};
  ${(props) =>
    props.midEphasis &&
    css`
      color: rgba(0, 0, 0, 0.6);
    `};
  ${(props) =>
    props.highEphasis &&
    css`
      color: rgba(0, 0, 0, 0.9);
    `};
  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
`;

export default P;
