import request from "../request";
import { GenericResponse } from "../types";
import {
  FetchReferralsResponse,
  ReferralListTypes,
  FetchReferralSummaryResponse,
  RedeemReferralCodeResponse,
  FetchReferralSummaryResponseV2,
} from "./types";

const fetchReferrals = async (listType: ReferralListTypes) => {
  return await request.get<FetchReferralsResponse>(
    `/v1.1/referral/${listType}`
  );
};

const fetchReferralSummary = async () => {
  return await request.get<FetchReferralSummaryResponse>(
    `/v1.1/referral/summary`
  );
};

const redeemReferralCode = async (code: string) => {
  return await request.post<RedeemReferralCodeResponse>(`/v1/misc/referral`, {
    code,
  });
};

const baseRequestOptionV2 = {
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
};

const redeemReferralCodeV2 = (code: string) => {
  return request.post<GenericResponse>(
    `/v1/referrals`,
    { code },
    baseRequestOptionV2
  );
};

const fetchReferralsV2 = (listType: ReferralListTypes) => {
  return request.get<FetchReferralsResponse>(
    `/v1/referrals/${listType}`,
    baseRequestOptionV2
  );
};

const fetchReferralSummaryV2 = () => {
  return request.get<FetchReferralSummaryResponseV2>(
    `/v1/referrals/summary`,
    baseRequestOptionV2
  );
};

const referralService = {
  fetchReferrals,
  fetchReferralSummary,
  redeemReferralCode,

  //V2
  fetchReferralsV2,
  fetchReferralSummaryV2,
  redeemReferralCodeV2,
};

export default referralService;
