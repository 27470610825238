import { combineReducers } from "redux";
import { StateType } from "typesafe-actions";

import addresses from "./addresses";
import ads from "./ads";
import auth from "./auth";
import banks from "./banks";
import cards from "./cards";
import deposits from "./deposits";
import prices from "./prices";
import orders from "./orders";
import profile from "./profile";
import referrals from "./referrals";
import market from "./market";
import tokens from "./tokens";
import transactions from "./transactions";
import transfers from "./transfers";
import wallets from "./wallets";
import stories from "./stories";
import trades from "./trades";
import { logoutUser } from "./auth/types";

export type ReducerStatusType =
  | "fetching"
  | "fetched"
  | "creating"
  | "created"
  | "updating"
  | "updated"
  | "deleting"
  | "deleted"
  | "verifying"
  | "verified"
  | "initializing"
  | "initialized"
  | "finalizing"
  | "finalized"
  | "cancelling"
  | "cancelled"
  | "error"
  | "idle"
  | "loading";

export type RootState = StateType<typeof RootReducer>;

const AppReducer = combineReducers({
  addresses,
  ads,
  auth,
  banks,
  cards,
  deposits,
  orders,
  prices,
  profile,
  referrals,
  market,
  tokens,
  transactions,
  transfers,
  wallets,
  stories,
  trades,
});

const RootReducer = (state: any, action: any) => {
  if (action.type === logoutUser.fulfilled) state = undefined;

  return AppReducer(state, action);
};

export default RootReducer;
