import { MarketPairType, FetchMarketPairGraphDataPayload } from "app/api/market/types";
import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";

export interface MarketState {
  data: {
    [symbol: string]: MarketPairType;
  };
  status: ReducerStatusType;
  error: string | null;
}

export const fetchMarketPairs = createActionType("fetch_market_pairs");
export const fetchMarketPairGraphData = createActionType("fetch_market_pair_graph_data");

export interface FetchMarketPairGraphDataAction {
  type: string;
  payload: FetchMarketPairGraphDataPayload;
}
