import request from "../request";
import { FetchCardsResponse, DeleteCardResponse } from "./types";

const fetchCards = async () => {
  return await request.get<FetchCardsResponse>(`/v1/card`);
};

const deleteCard = async (cardId: string) => {
  return await request.delete<DeleteCardResponse>(`/v1/card/${cardId}`);
};

const cardService = { fetchCards, deleteCard };

export default cardService;