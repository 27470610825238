export interface SideBarLink {
  name: string;
  path: string;
  exact?: boolean;
  children?: SideBarLink[];
  isBeta?: boolean;
  usePath?: boolean;
}

const routes: SideBarLink[] = [
  {
    name: "Wallets",
    path: "/wallets",
    exact: false,
    // children: [
    //   {
    //     name: "Spot",
    //     path: "",
    //   },
    //   {
    //     name: "Cards",
    //     path: "/cards",
    //     usePath: true,
    //   },
    // ],
  },
  {
    name: "Prices",
    path: "/prices",
    exact: true,
  },
  // {
  //   name: "P2P",
  //   path: "/p2p",
  //   exact: true,
  //   isBeta: true,
  //   children: [
  //     {
  //       name: "Trade",
  //       path: "",
  //       exact: true,
  //     },
  //     {
  //       name: "Post Ad",
  //       path: "post-ad",
  //       exact: false,
  //     },
  //     {
  //       name: "My Ads",
  //       path: "ads",
  //       exact: false,
  //     },
  //     {
  //       name: "My Trades",
  //       path: "trades",
  //       exact: false,
  //     },
  //   ],
  // },
  {
    name: "Activity",
    path: "/activity",
    exact: true,
  },
  {
    name: "Settings",
    path: "/settings",
    exact: true,
  },
];

export default routes;
