import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";
import {
  InitializeOrderPayload,
  InitializeOrderResponse,
  FinalizeOrderPayload,
  FinalizeOrderResponse,
  FetchTradeOrderBookResponse,
  FetchConvertOrderBookResponse,
  CreateRecurringOrderPayload,
  CreateRecurringOrderResponse,
} from "app/api/order/types";

export interface RecurringOrder {
  id: string;
  created_at: string;
  updated_at: string;
  next_scheduled: string;
  payment_method: string;
  user_id: string;
  product: string;
  base: string;
  quote: string;
  quote_amount: number;
  reference: string;
  frequency: string;
  last_payment_date: string;
  purchase_date: string;
}
export interface OrderBook {
  id: string;
  product: string;
  base: string;
  counter: string;
  hybrid: string;
  category: string;
  buy: boolean;
  sell: boolean;
  buy_price: number;
  maximum_purchase: number;
  minimum_purchase: number;
  sell_price: number;
  maximum_sale: number;
  minimum_sale: number;
  base_decimals: number;
  counter_decimals: number;
  readable_sell_price: string;
  readable_buy_price: string;
}

export interface OrdersState {
  data: {
    initializationData: InitializeOrderResponse | null;
    finalizationData: FinalizeOrderResponse | null;
    createRecurringOrderData: CreateRecurringOrderResponse | null;
    orderBooks: {
      trade: {
        [key: string]: FetchTradeOrderBookResponse;
      };
      convert: {
        [key: string]: FetchConvertOrderBookResponse;
      };
    };
    recurringOrders: {
      [key: string]: RecurringOrder;
    };
  };
  dataStatus: ReducerStatusType;
  actionStatus: ReducerStatusType;
  error: string | null;
}

export const fetchTradeOrderBooks = createActionType("fetch_trade_order_books");
export const fetchConvertOrderBooks = createActionType("fetch_convert_order_books");
export const initializeOrder = createActionType("initialize_order");
export const finalizeOrder = createActionType("finalize_order");
export const cancelOrder = createActionType("cancel_order");
export const fetchRecurringOrders = createActionType("fetch_recurring_orders");
export const fetchRecurringOrder = createActionType("fetch_recurring_order");
export const createRecurringOrder = createActionType("create_recurring_order");
export const cancelRecurringOrder = createActionType("cancel_recurring_order");
export const clearOrder = createActionType("clear_order");
export const clearOrderError = createActionType("clear_order_error");

export interface OrderAction {
  type: string;
  payload: {
    orderId: string;
  };
}
export interface FetchTradeOrderBooksAction {
  type: string;
  payload: {
    currencyCode: string;
  };
}

export interface FetchConvertOrderBooksAction {
  type: string;
  payload: {
    currencyCode: string;
  };
}

export interface InitializeOrderAction {
  type: string;
  payload: InitializeOrderPayload;
}

export interface FinalizeOrderAction {
  type: string;
  payload: FinalizeOrderPayload;
}

export type CancelOrderAction = OrderAction;

export type FetchRecurringOrderAction = OrderAction;
export interface CreateRecurringOrderAction {
  type: string;
  payload: CreateRecurringOrderPayload;
}

export type CancelRecurringOrderAction = OrderAction;
