import { addBankAccount, BankAccount } from "./types";
import { BanksState, fetchBanks, deleteBankAccount, fetchBankAccounts } from "./types";

const INITIAL_STATE: BanksState = {
  error: null,
  dataStatus: "idle",
  actionStatus: "idle",
  data: {
    banks: [],
    accounts: [],
  },
};

export default function banks(state = INITIAL_STATE, action: any): BanksState {
  switch (action.type) {
    case fetchBanks.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchBanks.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchBanks.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          banks: action.payload,
        },
      };

    case fetchBankAccounts.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchBankAccounts.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchBankAccounts.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          accounts: action.payload,
        },
      };

    case addBankAccount.pending:
      return {
        ...state,
        actionStatus: "creating",
        error: null,
      };

    case addBankAccount.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case addBankAccount.fulfilled: {
      const newBankAccount = action.payload as BankAccount;
      return {
        ...state,
        actionStatus: "created",
        error: null,
        data: {
          ...state.data,
          accounts: [...state.data.accounts, newBankAccount],
        },
      };
    }

    case deleteBankAccount.pending:
      return {
        ...state,
        actionStatus: "deleting",
        error: null,
      };

    case deleteBankAccount.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case deleteBankAccount.fulfilled: {
      const deletedBankAccountId = action.payload.bankAccountId;
      const remainingBankAccounts = state.data.accounts.filter(
        (account) => account.id !== deletedBankAccountId
      );
      return {
        ...state,
        actionStatus: "deleted",
        error: null,
        data: {
          ...state.data,
          accounts: remainingBankAccounts,
        },
      };
    }

    default:
      return state;
  }
}
