import request from "../request";
import { FetchPricesResponse } from "./types";

const fetchPrices = async (currency: string) => {
  return await request.get<FetchPricesResponse>(`/v1/prices/${currency}`);
};

const priceService = { fetchPrices };

export default priceService;
