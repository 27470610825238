import {
  AdType,
  FetchAdsPayload,
  CreateAdPayload,
  EditAdPayload,
  SetAdStatusPayload,
  ProductType,
  FetchCashConnectAdsPayload,
  CashConnectAdType,
} from "app/api/ads/types";
import { PaginationMetaType } from "app/api/types";
import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";
import {} from "../../api/ads/types";

export interface AdsState {
  data: {
    ads: {
      data: { [adId: string]: AdType };
      meta: PaginationMetaType;
    };
    cashConnect: {
      data: { [adId: string]: CashConnectAdType };
      meta: PaginationMetaType;
    };
    products: { [code: string]: ProductType };
  };
  status: ReducerStatusType;
  error: string | null;
}

export const fetchAds = createActionType("fetch_ads");
export const fetchCashConnectAds = createActionType("fetch_cash_connect_ads");
export const fetchAd = createActionType("fetch_ad");
export const fetchAdProducts = createActionType("fetch_ad_products");
export const createAd = createActionType("create_ad");
export const editAd = createActionType("edit_ad");
export const setAdStatus = createActionType("set_ad_status");

export interface FetchAdsAction {
  type: string;
  payload: FetchAdsPayload;
}

export interface FetchCashConnectAdsAction {
  type: string;
  payload: FetchCashConnectAdsPayload;
}

export interface FetchAdAction {
  type: string;
  payload: {
    adId: string;
  };
}

export interface CreateAdAction {
  type: string;
  payload: CreateAdPayload;
}

export interface EditAdAction {
  type: string;
  payload: { adId: string; payload: EditAdPayload };
}

export interface SetAdStatusAction {
  type: string;
  payload: SetAdStatusPayload;
}
