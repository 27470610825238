import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";

export interface Card {
  id: string;
  created_at: string;
  brand: string;
  bank: string;
  exp_year: string;
  exp_month: string;
  last4: string;
  type: string;
}

export interface CardsState {
  data: Card[];
  dataStatus: ReducerStatusType;
  actionStatus: ReducerStatusType;
  error: string | null;
}

export const fetchCards = createActionType("fetch_cards");
export const deleteCard = createActionType("delete_card");

export interface DeleteCardAction {
  type: string;
  payload: {
    cardId: string;
  };
}
