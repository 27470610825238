import { ProfileState, fetchProfile } from "./types";
import session from "app/lib/session";

const cachedProfile = session.get("profile");

const INITIAL_STATE: ProfileState = {
  status: "idle",
  error: null,
  data: cachedProfile,
};

export default function profile(state = INITIAL_STATE, action: any): ProfileState {
  switch (action.type) {
    case fetchProfile.pending:
      return {
        ...state,
        status: "fetching",
        error: null,
      };

    case fetchProfile.rejected:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };

    case fetchProfile.fulfilled:
      return {
        ...state,
        status: "fetched",
        error: null,
        data: action.payload,
      };

    default:
      return state;
  }
}
