import { CardsState, fetchCards, deleteCard } from "./types";

const INITIAL_STATE: CardsState = {
  error: null,
  dataStatus: "idle",
  actionStatus: "idle",
  data: [],
};

export default function cards(state = INITIAL_STATE, action: any): CardsState {
  switch (action.type) {
    case fetchCards.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchCards.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchCards.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: action.payload,
      };
    case deleteCard.pending:
      return {
        ...state,
        actionStatus: "deleting",
        error: null,
      };

    case deleteCard.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case deleteCard.fulfilled: {
      const deletedCardId = action.payload.cardId;
      const remainingCards = state.data.filter(
        (card) => card.id !== deletedCardId
      );
      return {
        ...state,
        actionStatus: "idle",
        error: null,
        data: remainingCards,
      };
    }

    default:
      return state;
  }
}
