import request from "../request";
import { GenericResponse } from "../types";
import {
  FetchBanksResponse,
  FetchBankAccountsResponse,
  AddBankAccountResponse,
  AddBankAccountPayload,
  DeleteBankAccountResponse,
} from "./types";

const fetchBanks = async (countryCode: string) => {
  return await request.get<FetchBanksResponse>(
    `/v1.1/banks/list/${countryCode}`
  );
};

const fetchBankAccounts = async () => {
  return await request.get<FetchBankAccountsResponse>(`/v1.1/banks`);
};

const addBankAccount = async (payload: AddBankAccountPayload) => {
  return await request.post<AddBankAccountResponse>(`/v1.1/banks`, payload);
};

const deleteBankAccount = async (bankAccountId: string) => {
  return await request.delete<DeleteBankAccountResponse>(
    `/v1.1/banks/${bankAccountId}`
  );
};

const baseRequestOptionV2 = {
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
};

const fetchBankAccountsV2 = () => {
  return request.get<FetchBankAccountsResponse>(
    "/v1/banks",
    baseRequestOptionV2
  );
};

const createBankAccountV2 = (payload: AddBankAccountPayload) => {
  return request.post<GenericResponse>(
    `/v1/banks`,
    payload,
    baseRequestOptionV2
  );
};

const deleteBankAccountV2 = (id: string) => {
  return request.delete<GenericResponse>(
    `/v1/banks/${id}`,
    baseRequestOptionV2
  );
};

const bankService = {
  fetchBanks,
  fetchBankAccounts,
  addBankAccount,
  deleteBankAccount,

  //v2
  fetchBankAccountsV2,
  createBankAccountV2,
  deleteBankAccountV2,
};

export default bankService;
