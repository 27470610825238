import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest, takeEvery } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import {
  fetchReferrals,
  FetchReferralsAction,
  fetchReferralSummary,
  RedeemReferralCodeAction,
  redeemReferralCode,
} from "./types";
import {
  FetchReferralsResponse,
  RedeemReferralCodeResponse,
  FetchReferralSummaryResponse,
} from "app/api/referral/types";
import { notify } from "app/utils/toast";

function* fetchReferralsSaga(action: FetchReferralsAction) {
  try {
    yield put({ type: fetchReferrals.pending });

    const { listType } = action.payload;

    const res: AxiosResponse<FetchReferralsResponse> = yield call(
      api.referralService.fetchReferrals,
      listType
    );
    const data = res.data;
    yield put({ type: fetchReferrals.fulfilled, payload: data, listType });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchReferrals.rejected, payload: errorMessage });
  }
}

function* fetchReferralSummarySaga() {
  try {
    yield put({ type: fetchReferralSummary.pending });

    const res: AxiosResponse<FetchReferralSummaryResponse> = yield call(
      api.referralService.fetchReferralSummary
    );
    const data = res.data;
    yield put({ type: fetchReferralSummary.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchReferralSummary.rejected, payload: errorMessage });
  }
}

function* redeemReferralCodeSaga(action: RedeemReferralCodeAction) {
  try {
    yield put({ type: redeemReferralCode.pending });

    const { code } = action.payload;

    const res: AxiosResponse<RedeemReferralCodeResponse> = yield call(
      api.referralService.redeemReferralCode,
      code
    );
    const data = res.data;
    yield put({ type: redeemReferralCode.fulfilled, payload: data });

    yield call(notify, "You have successfully redeemed a referral code", {
      variant: "success",
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, {
      variant: "error",
    });
    yield put({ type: redeemReferralCode.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeEvery(fetchReferrals.default, fetchReferralsSaga),
    takeLatest(fetchReferralSummary.default, fetchReferralSummarySaga),
    takeLatest(redeemReferralCode.default, redeemReferralCodeSaga),
  ]);
}
