import {
  CreateTradePayload,
  GetAllTradesPayload,
  GetSingleTradePayload,
  SetTradeStatusPayload,
  SummonTraderPayload,
  CreateDisputePayload,
  Trade,
  CreateTradeResponse,
  GetDisputeResponse,
  CreateReviewPayload,
  ExportTradesPayload,
} from "app/api/trades/types";
import { PaginationMetaType } from "app/api/types";
import { ReducerStatusType } from "../reducers";
import { GenericAction } from "../types";
import createActionType from "../util/createActionType";

export interface TradesState {
  trades: { [key: string]: Trade };
  meta: PaginationMetaType;
  createdTrade: CreateTradeResponse | null;
  status:
    | ReducerStatusType
    | "summoning"
    | "summoned"
    | "disputing"
    | "disputed"
    | "reviewing"
    | "reviewed"
    | "exporting"
    | "exported";
  error: string | null;
  dispute: {
    response?: GetDisputeResponse;
    error: string | null;
    status: ReducerStatusType;
  };
}

export const getAllTrades = createActionType("get_all_trades");
export const getSingleTrade = createActionType("get_single_trade");
export const createTrade = createActionType("create_trade");
export const setTradeStatus = createActionType("set_trade_status");
export const summonTrader = createActionType("summon_trader");
export const createDispute = createActionType("create_dispute");
export const getSingleDispute = createActionType("get_single_dispute");
export const createReview = createActionType("create_review");
export const exportTrades = createActionType("export_trades");

export interface GetAllTradesAction extends GenericAction {
  payload: GetAllTradesPayload;
}

export interface GetSingleTradeAction extends GenericAction {
  payload: GetSingleTradePayload;
}

export interface CreateTradeAction extends GenericAction {
  payload: CreateTradePayload;
}

export interface SetTradeStatusAction extends GenericAction {
  payload: SetTradeStatusPayload;
}

export interface SummonTraderAction extends GenericAction {
  payload: SummonTraderPayload;
}
export interface CreateDisputeAction extends GenericAction {
  payload: CreateDisputePayload;
}

export interface GetSingleDisputeAction extends GenericAction {
  payload: string;
}

export interface CreateReviewAction extends GenericAction {
  payload: CreateReviewPayload;
}

export interface ExportTradesAction {
  type: string;
  payload: ExportTradesPayload;
}
