import { format, formatDistance } from "date-fns/esm";
import { Card } from "app/redux/cards/types";
import { AdAction, AdSideType } from "app/api/ads/types";
import { formatMoneyNoCurrency } from "../lib/money";
import { TradeStatus } from "../api/trades/types";

/**
 * Wrap an async operation with an error handler
 */
export const asyncWrap = (promise: Promise<any>) =>
  promise.then((result: any) => [null, result]).catch((err: Error) => [err]);

/**
 * Get file size of a File object in kilobytes
 */
export const getFileSizeInKB = (size: number) => parseInt(String(size / 1024));

/**
 * Decode string
 */
export const encodeString = (str: string) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );

/**
 * Decode string
 */
export const decodeString = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        atob(str),
        (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join("")
  );

/**
 * Get english possessive nouns
 */
export const getPossessiveNoun = (noun: string) => {
  if (noun.endsWith("s")) {
    return `${noun}'`;
  }
  return `${noun}'s`;
};

/**
 * Get scale for text
 */
export function scaleBetween(
  unscaledNum: number,
  minAllowed: number,
  maxAllowed: number,
  min: number,
  max: number
) {
  return (
    ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed
  );
}

/**
 * get the number value of a Signed Number string
 */

export function parseNumberStringWithSign(moneyString: string) {
  if (moneyString.charAt(0) === "-" || moneyString.charAt(0) === "+") {
    const valueWithoutSign = moneyString.substr(1);
    if (!isNaN(Number(valueWithoutSign))) {
      return Number(valueWithoutSign);
    }
  }

  return moneyString;
}

/**
 * Get bank slug string from bank name string
 */
export function getBankSlug(name: string) {
  return name.split(" ").join("-").toLowerCase();
}

export function getWordFormBasedOnQuantity(
  word: string,
  quantity: number | string
) {
  if (Number(quantity) === 1) {
    return `${word}`;
  }
  return `${word}s`;
}

/**
 * Create object from an array of objects
 */
type ObjectType = {
  [key: string]: any;
};

export function mapKeys<T extends Array<ObjectType>>(
  items: T,
  key: string | string[],
  exclude: string[] = []
): { [key: string]: T[number] } {
  return items.reduce((obj, currentObj) => {
    const newObject = Object.fromEntries(
      Object.entries(currentObj).filter(([key]) => !exclude.includes(key))
    );

    if (typeof key === "string") {
      obj[currentObj[key]] = newObject;
    } else {
      let newKey = currentObj[key[0]];
      for (let index = 1; index < key.length; index++) {
        newKey = `${newKey}_${currentObj[key[index]]}`;
      }
      obj[newKey] = newObject;
    }
    return obj;
  }, {});
}

/**
 * Create map from an array of objects
 */
export function createMap(
  items: ObjectType[],
  key: string,
  mappedKey?: string
) {
  const map = new Map();
  items.forEach((item) => {
    map.set(item[key], mappedKey ? item[mappedKey] : item);
  });
  return map;
}

/**
 * Format date string to a `{MMM} {dd}, {yyyy}` format
 */
export function formatDateStr(dateStr: string) {
  return format(new Date(dateStr), "MMM dd, yyyy");
}

/**
 * Get time string form a date string
 */
export function getDateTime(dateStr: string) {
  return format(new Date(dateStr), "HH:mm a");
}

/**
 * Format the transaction amount which comes in the format of '{CURRENCY} {AMOUNT}`
 */
export function formatTransactionAmountStr(
  str: string,
  type: "debit" | "credit" = "credit"
) {
  const [currency, amount] = str.split(" ");
  const formattedAmount = formatMoneyNoCurrency(Number(amount), undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 8,
  });
  const sign = type === "credit" ? "" : "-";

  return `${sign}${formattedAmount} ${currency}`;
}

/**
 * Get Card Expiry date
 */
export function getCardExpiryDate(card: Card | null) {
  return card?.exp_year && card?.exp_month
    ? new Date(
        Number(card.exp_year),
        Number(card.exp_month),
        new Date(Number(card.exp_year), Number(card?.exp_month)).getDate()
      )
    : new Date();
}

/**
 * Get Card Expiry distance
 */
export function getCardExpiryDistance(cardExpiryDate: Date, compareDate: Date) {
  return formatDistance(cardExpiryDate, compareDate, { addSuffix: true });
}

/**
 * Format Ticker timestamp
 * @param timestamp
 * @param span
 */
export function formatDateTime(timestamp: Date) {
  return format(timestamp, "MMM, dd yyyy - hh:mm a");
}

/**
 * Capitalize a string
 */
export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

/**
 * Capitalize a sentence
 */
export function capitalizeSentence(sentence: string) {
  return sentence.split(" ").map(capitalize).join(" ");
}

/**
 * Get Cardinal number - e.g 1 => 1st
 */
export const getCardinalNumber = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

interface AssociatedPrices {
  tickerPrice: string;
  rate: string;
  minimum: string;
  maximum: string;
}

export const getAdsAssociatedPrices = (prices: AssociatedPrices) => {
  const tickerPrice = Number(prices.tickerPrice);
  const rate = Number(prices.rate);
  const maximumFractionDigits = 3;
  const adsPrice = formatMoneyNoCurrency(rate * tickerPrice, "digital", {
    maximumFractionDigits,
  });
  const parsedMinimumPrice = Number(
    (Number(prices.minimum) * tickerPrice * rate).toFixed(maximumFractionDigits)
  );
  const parsedMaximumPrice = Number(
    (Number(prices.maximum) * tickerPrice * rate).toFixed(maximumFractionDigits)
  );
  const minimumPrice = formatMoneyNoCurrency(parsedMinimumPrice, "digital", {
    maximumFractionDigits,
  });
  const maximumPrice = formatMoneyNoCurrency(parsedMaximumPrice, "digital", {
    maximumFractionDigits,
  });

  return {
    adsPrice,
    minimumPrice,
    maximumPrice,
    parsedMaximumPrice,
    parsedMinimumPrice,
  };
};

export const getTheOppositeOfTheAdSide = (side: AdSideType): AdSideType => {
  if (side === "buy") return "sell";
  return "buy";
};

export const getActionEquivalentOfAdSide = (side: AdSideType): AdAction => {
  if (side === "buy") return "deposit";
  return "withdraw";
};

export const isEntityEmpty = (
  data: string | ObjectType | Array<any>
): boolean => {
  if (typeof data == "string") {
    return !data.trim().length;
  }

  if (Array.isArray(data)) return !data.length;

  return !Object.keys(data).length;
};

export const isTheFirstParameterGreaterThanTheSecondParameter = (
  firstParam: string | number,
  secondParam: string | number
): boolean => {
  if (Number(firstParam) > Number(secondParam)) return true;
  return false;
};

export const formatTradeStatus = (status: TradeStatus) => {
  switch (status) {
    case "processing":
      return "awaiting confirmation";
    case "pending":
      return "awaiting payment";
    default:
      return status;
  }
};
