import { format as formatDate } from "date-fns";
import queryString from "query-string";
import request from "../request";
import {
  DownloadTransactionStatementPayload,
  DownloadTransactionStatementResponse,
  FetchTransactionCategoriesResponseV2,
  FetchTransactionResponseV2,
  FetchTransactionsQueryV2,
  FetchTransactionsResponseV2,
} from "./types";
import {
  FetchTransactionsPayload,
  FetchTransactionsResponse,
  FetchTransactionResponse,
} from "./types";

const fetchTransactions = async (payload: FetchTransactionsPayload) => {
  const query = queryString.stringify(payload, {
    arrayFormat: "comma",
    skipEmptyString: true,
    skipNull: true,
  });
  return await request.get<FetchTransactionsResponse>(
    `/v1.1/transactions?${query}`
  );
};

const fetchTransaction = async (transactionId: string) => {
  return await request.get<FetchTransactionResponse>(
    `/v1.1/transactions/${transactionId}`
  );
};

const downloadTransactionStatement = async (
  payload: DownloadTransactionStatementPayload
) => {
  const { currency, start, end, format } = payload;
  const query = queryString.stringify(
    {
      start: formatDate(start, "yyyy-MM-dd"),
      end: formatDate(end, "yyyy-MM-dd"),
      format,
    },
    {
      skipEmptyString: true,
      skipNull: true,
    }
  );
  return await request.get<DownloadTransactionStatementResponse>(
    `/v1.1/transactions/${currency}/statement?${query}`,
    { responseType: "blob" }
  );
};

const baseRequestOptionV2 = {
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
};

const fetchTransactionsV2 = (payload: FetchTransactionsQueryV2) => {
  const query = queryString.stringify(payload, {
    skipEmptyString: true,
    skipNull: true,
    arrayFormat: "comma",
  });

  return request.get<FetchTransactionsResponseV2>(
    `/v1/transactions?${query}`,
    baseRequestOptionV2
  );
};

const fetchTransactionV2 = (id: string) => {
  return request.get<FetchTransactionResponseV2>(
    `/v1/transactions/${id}`,
    baseRequestOptionV2
  );
};

const fetchTransactionCategories = () => {
  return request.get<FetchTransactionCategoriesResponseV2>(
    `/v1/transactions/category`,
    baseRequestOptionV2
  );
};

const downloadTransactionStatementV2 = async (
  payload: DownloadTransactionStatementPayload
) => {
  const { currency, start, end, format } = payload;
  const query = queryString.stringify(
    {
      start: formatDate(start, "yyyy-MM-dd"),
      end: formatDate(end, "yyyy-MM-dd"),
      format,
    },
    {
      skipEmptyString: true,
      skipNull: true,
    }
  );
  return await request.get<DownloadTransactionStatementResponse>(
    `/v1/transactions/${currency}/statement?${query}`,
    { responseType: "blob", ...baseRequestOptionV2 }
  );
};

const transactionService = {
  fetchTransactions,
  fetchTransaction,
  downloadTransactionStatement,

  //V2
  fetchTransactionsV2,
  fetchTransactionV2,
  fetchTransactionCategories,
  downloadTransactionStatementV2,
};

export default transactionService;
