import request from "../request";
import { FetchProfileResponse, FetchProfileResponseV2 } from "./types";

const fetchProfile = async () => {
  return await request.get<FetchProfileResponse>(
    `${process.env.REACT_APP_AUTH_API_URL}/v3/me`
  );
};

const fetchProfileV2 = () => {
  return request.get<FetchProfileResponseV2>(
    `${process.env.REACT_APP_AUTH_API_URL}/v3/me`
  );
};

const profileService = { fetchProfile, fetchProfileV2 };

export default profileService;
