import React from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import LoaderBox from "./LoaderBox/index";
import { TokensState } from "../redux/tokens/types";
import { TokenActions, TokenDispatch } from "app/providers/TokensProvider";

interface Props {}

interface Params {
  token: string;
}

const Oauth: React.FC<Props> = (props) => {
  const params = useParams<Params>();
  const location = useLocation();
  const history = useHistory();

  const dispatch = React.useContext(TokenDispatch);

  React.useEffect(() => {
    const accessToken = params.token;
    let payload: TokensState = {
      access_token: accessToken,
    };
    const refreshToken = new URLSearchParams(location.search).get(
      "refreshToken"
    );
    if (refreshToken) {
      payload = { ...payload, refresh_token: refreshToken };
    }

    dispatch({ type: TokenActions.setTokens, payload });
    history.push("/wallets");
  }, [dispatch, history, location.search, params.token]);

  return <LoaderBox />;
};

export default Oauth;
