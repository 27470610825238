import styled from "styled-components";
import media from "app/styles/utils/media";

export const Container = styled.div`
  /* padding: 0px 20px; */
  /* width: 1080px; */
  width: 1120px;
  /* width: 1280px; */
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.smallDesktop`
    padding: 0px 20px;
  `};
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileSidebarButton = styled.button`
  border: none;
  /* outline: none; */
  background: transparent;
  display: none;
  margin-right: 16px;
  ${media.smallDesktop`
    display: block;
  `};
`;

export const Logo = styled.div`
  & > svg {
    height: 28px;
    ${media.mobile`
      height: 22px;
    `};
  }
`;
