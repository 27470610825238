import { createGlobalStyle } from "styled-components";

const FONT_BASE_URL =
  "https://res.cloudinary.com/busha-inc/raw/upload/v1651833681/fonts/Roobert";

const GlobalStyles = createGlobalStyle`
    @font-face {
    font-family: "Roobert";
    src: url("${FONT_BASE_URL}/Roobert-Light.otf");
    font-weight: 100;
    }

    @font-face {
    font-family: "Roobert";
    src: url("${FONT_BASE_URL}/Roobert-Regular.otf");
    font-weight: normal;
    }

    @font-face {
    font-family: "Roobert";
    src: url("${FONT_BASE_URL}/Roobert-Medium.otf");
    font-weight: 500;
    }

    @font-face {
    font-family: "Roobert";
    src: url("${FONT_BASE_URL}/Roobert-Bold.otf");
    font-weight: bold;
    }


    * {
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
    background-color: transparent;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    *::-webkit-scrollbar {
    display: none;
    }

    /* Hide scrollbar for IE and Edge and Old Firefox */
    * {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    }

    html {
    font-size: 20px;
    color: #000000;
    }

    html,
    body {
    width: 100%;
    height: 100%;
    background-color: #fff;
    }

    body {
    font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

    button {
    cursor: pointer;
    font-family: inherit;
    }

    button:disabled {
    cursor: not-allowed;
    }

    #root {
    height: 100%;
    width: 100%;
    }

    code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }

    input[type="number"] {
    -moz-appearance: textfield;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    .Toastify__toast-container.toast__container,
    .Toastify__toast.toast__toast,
    .Toastify__toast-body.toast__body {
    font-family: "Roobert";
    padding: 0px;
    margin: 0;
    border-radius: 4px;
    width: unset;
    }

    .Toastify__toast-container.toast__container,
    .Toastify__toast-body.toast__body {
    width: unset;
    max-width: 400px;
    right: 0;
    }
    .Toastify__toast.toast__toast {
    margin-bottom: 1rem;
    }

    .Toastify__toast.toast__toast {
    min-height: auto;
    }

    @media only screen and (max-width: 480px) {
    .Toastify__toast-container.toast__container {
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        padding: 20px;
    }
    }
`;

export default GlobalStyles;
