import React from "react";
import { Link } from "react-router-dom";

import {
  MobileContainer,
  Sidenav,
  Links,
  MobileLink,
  CloseButton,
} from "./styles";
import routes from "./routes";
import { ReactComponent as LogoImg } from "app/assets/img/logo.svg";
import { ReactComponent as CloseIcon } from "app/assets/img/icons/close.svg";
import { LinkWithChildren } from "./index";

interface Props {
  show: boolean;
  onMobileCloseSidebar: () => void;
}

export const MobileSidebar: React.FC<Props> = (props) => {
  const onLinkClicked = () => props.onMobileCloseSidebar();
  return (
    <MobileContainer show={props.show}>
      <CloseButton>
        <CloseIcon onClick={props.onMobileCloseSidebar} />
      </CloseButton>
      <Sidenav>
        <div className="logo">
          <Link to="/wallets">
            <LogoImg />
          </Link>
        </div>
        <Links>
          {routes.map((r) =>
            !r.children ? (
              <MobileLink key={r.name} to={r.path} onClick={onLinkClicked}>
                {r.name}
              </MobileLink>
            ) : (
              <LinkWithChildren key={r.name} link={r} onClick={onLinkClicked} />
            )
          )}
        </Links>
      </Sidenav>
    </MobileContainer>
  );
};

export default MobileSidebar;
