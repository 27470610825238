import React from "react";

import { OuterBox, LoaderProp } from "./styles";

interface Props extends LoaderProp {
  className?: string;
}
const Loader: React.FC<Props> = (props) => {
  const { size, outerColor, innerColor, color, stroke } = props;
  return (
    <OuterBox
      size={size}
      outerColor={outerColor}
      innerColor={innerColor}
      color={color}
      stroke={stroke}
      className={props.className}
    >
      {/* <InnerBox size={size} innerColor={innerColor} /> */}
    </OuterBox>
  );
};

export default Loader;
