import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import { fetchCards, deleteCard, DeleteCardAction } from "./types";
import { FetchCardsResponse } from "app/api/card/types";

function* fetchCardsSaga() {
  try {
    yield put({ type: fetchCards.pending });

    const res: AxiosResponse<FetchCardsResponse> = yield call(
      api.cardService.fetchCards
    );
    const data = res.data;
    yield put({ type: fetchCards.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchCards.rejected, payload: errorMessage });
  }
}

function* deleteCardSaga(action: DeleteCardAction) {
  try {
    yield put({ type: deleteCard.pending });

    const { cardId } = action.payload;

    yield call(api.cardService.deleteCard, cardId);
    yield put({ type: deleteCard.fulfilled, payload: { cardId } });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: deleteCard.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchCards.default, fetchCardsSaga),
    takeLatest(deleteCard.default, deleteCardSaga),
  ]);
}
