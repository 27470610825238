import createActionType from "../util/createActionType";
import { FetchProfileResponse } from "app/api/profile/types";
import { ReducerStatusType } from "../reducers";

export interface ProfileState {
  data: FetchProfileResponse | null;
  status: ReducerStatusType;
  error: FetchProfileResponse extends string ? string | null : FetchProfileResponse | null;
}

export const fetchProfile = createActionType("fetch_profile");
