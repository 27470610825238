import { MarketState, fetchMarketPairs, fetchMarketPairGraphData } from "./types";

const INITIAL_STATE: MarketState = {
  error: null,
  status: "idle",
  data: {},
};

export default function market(state = INITIAL_STATE, action: any): MarketState {
  switch (action.type) {
    case fetchMarketPairs.pending:
      return {
        ...state,
        status: "fetching",
        error: null,
      };

    case fetchMarketPairs.rejected:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };

    case fetchMarketPairs.fulfilled:
      return {
        ...state,
        status: "fetched",
        error: null,
        data: action.payload,
      };

    case fetchMarketPairGraphData.pending:
      return {
        ...state,
        status: "fetching",
        error: null,
      };

    case fetchMarketPairGraphData.rejected:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };

    case fetchMarketPairGraphData.fulfilled:
      return {
        ...state,
        status: "fetched",
        error: null,
        data: {
          ...state.data,
          [action.meta.symbol]: {
            ...state.data?.[action.meta.symbol],
            ohlc: action.payload,
          },
        },
      };

    default:
      return state;
  }
}
