import { all, put, call } from "redux-saga/effects";
import storage from "app/lib/storage";
import session from "app/lib/session";

import { logoutUser } from "./types";
// import { RootState } from "../reducers";
// import { TokensState } from "../tokens/types";
import { takeFirst } from "../util/effects";

function* logoutSaga() {
  // const token: TokensState = yield select((state: RootState) => state.tokens);
  // console.log("token :>> ", token);
  // if (!token || !token.access_token) return;

  yield put({ type: logoutUser.pending });
  yield call([localStorage, storage.clear]);
  yield call([sessionStorage, session.clear]);
  yield put({ type: logoutUser.fulfilled });
  yield call(
    [window.location, window.location.replace],
    `${process.env.REACT_APP_AUTH_APP_URL}/login?redirect=${encodeURIComponent(
      window.location.origin
    )}&logout=true`
  );
}

export default function* allSaga() {
  yield all([takeFirst(logoutUser.default, logoutSaga)]);
}
