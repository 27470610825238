import React from "react";
import { useDispatch } from "react-redux";
import useAuth from "app/hooks/useAuth";
import LogUserOut from "app/lib/auth";

interface Props {}

const AuthRoutesWrapper: React.FC<Props> = (props) => {
  const isLoggedIn = useAuth();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isLoggedIn) {
      LogUserOut();
    }
  }, [dispatch, isLoggedIn]);

  return <>{props.children}</>;
};

export default AuthRoutesWrapper;
