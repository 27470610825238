import request from "../request";
import { GenericResponse } from "../types";
import {
  InitializeOrderPayload,
  InitializeOrderResponse,
  FinalizeOrderPayload,
  FinalizeOrderResponse,
  CancelOrderResponse,
  FetchRecurringOrderResponse,
  CreateRecurringOrderPayload,
  CreateRecurringOrderResponse,
  FetchTradeOrderBookResponse,
  FetchConvertOrderBookResponse,
  CancelRecurringOrderResponse,
  CreateRecurringOrderPayloadV2,
  CreateRecurringOrderResponseV2,
  FetchRecurringOrdersQueryV2,
  FetchRecurringOrdersResponseV2,
  FetchRecurringOrderHistories,
  EditRecurringOrderPayload,
  CreateLimitOrderPayload,
  CreateLimitOrderResponse,
  GetLimitOrderResponse,
  InitializeOrderPayloadV2,
  FinalizeOrderPayloadV2,
} from "./types";

const fetchTradeOrderBooks = (currencyCode: string) => {
  return request.get<FetchTradeOrderBookResponse>(
    `/v1.1/orders/${currencyCode}/trade`
  );
};

const fetchConvertOrderBooks = (currencyCode: string) => {
  return request.get<FetchConvertOrderBookResponse>(
    `/v1.1/orders/${currencyCode}/convert`
  );
};

const initializeOrder = (payload: InitializeOrderPayload) => {
  return request.post<InitializeOrderResponse>(
    `/v1.1/orders/initialize`,
    payload
  );
};

const finalizeOrder = (payload: FinalizeOrderPayload) => {
  return request.post<FinalizeOrderResponse>(`/v1.1/orders/finalize`, payload);
};

const cancelOrder = (orderId: string) => {
  return request.delete<CancelOrderResponse>(`/v1.1/orders/${orderId}`);
};

const fetchRecurringOrders = () => {
  return request.get<FetchRecurringOrderResponse>(`/v1.1/orders/recurring`);
};

const fetchRecurringOrder = (orderId: string) => {
  return request.get<FetchRecurringOrderResponse>(
    `/v1.1/orders/recurring/${orderId}`
  );
};

const createRecurringOrder = (payload: CreateRecurringOrderPayload) => {
  return request.post<CreateRecurringOrderResponse>(
    `/v1.1/orders/recurring`,
    payload
  );
};

const cancelRecurringOrder = (orderId: string) => {
  return request.delete<CancelRecurringOrderResponse>(
    `/v1.1/orders/recurring/${orderId}`
  );
};

const baseRequestOptionV2 = {
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
};

const initializeOrderV2 = (payload: InitializeOrderPayloadV2) => {
  return request.post<InitializeOrderResponse>(
    `/v1/order/initialize`,
    payload,
    baseRequestOptionV2
  );
};

const finalizeOrderV2 = (payload: FinalizeOrderPayloadV2) => {
  return request.post<FinalizeOrderResponse>(
    `/v1/order/finalize`,
    payload,
    baseRequestOptionV2
  );
};

const createRecurringOrderV2 = (payload: CreateRecurringOrderPayloadV2) => {
  return request.post<CreateRecurringOrderResponseV2>(
    `/v1/order/recurring`,
    payload,
    baseRequestOptionV2
  );
};

const fetchRecurringOrdersV2 = (query: FetchRecurringOrdersQueryV2) => {
  return request.get<FetchRecurringOrdersResponseV2>(`/v1/order/recurring`, {
    ...baseRequestOptionV2,
    params: query,
  });
};

const fetchRecurringOrderHistories = (id: string) => {
  return request.get<FetchRecurringOrderHistories>(
    `/v1/order/recurring/${id}/history`,
    baseRequestOptionV2
  );
};

const editRecurringOrder = (id: string, payload: EditRecurringOrderPayload) => {
  return request.put<GenericResponse>(
    `/v1/order/recurring/${id}`,
    payload,
    baseRequestOptionV2
  );
};

const deleteRecurringOrder = (id: string) => {
  return request.delete<GenericResponse>(
    `/v1/order/recurring/${id}`,
    baseRequestOptionV2
  );
};

const createLimitOrder = (payload: CreateLimitOrderPayload) => {
  return request.post<CreateLimitOrderResponse>(
    `/v1/order/limit`,
    payload,
    baseRequestOptionV2
  );
};

const getLimitOrder = (id: string) => {
  return request.get<GetLimitOrderResponse>(
    `/v1/order/limit/${id}`,
    baseRequestOptionV2
  );
};

const deleteLimitOrder = (id: string) => {
  return request.delete<GenericResponse>(
    `/v1/order/limit/${id}`,
    baseRequestOptionV2
  );
};

//TODO pause and resume recurring order

const orderService = {
  fetchTradeOrderBooks,
  fetchConvertOrderBooks,
  initializeOrder,
  finalizeOrder,
  cancelOrder,
  fetchRecurringOrders,
  fetchRecurringOrder,
  createRecurringOrder,
  cancelRecurringOrder,

  //V2
  initializeOrderV2,
  finalizeOrderV2,
  createRecurringOrderV2,
  fetchRecurringOrdersV2,
  fetchRecurringOrderHistories,
  editRecurringOrder,
  deleteRecurringOrder,
  createLimitOrder,
  getLimitOrder,
  deleteLimitOrder,
};

export default orderService;
