import React from "react";
import { useDispatch } from "react-redux";
import { Container, Avatar, DropdownButton, UserName, Dropdown } from "./styles";
import P from "app/styles/elements/P";
import ClickAway from "../ClickAway";

import { logout } from "app/redux/auth/actions";

interface Props {
  fullName?: string;
  avatar?: string;
  email?: string;
}

const UserDropdown: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const [showDropdown, setShowDropDown] = React.useState(false);
  const toggleDropdown = () => setShowDropDown((s) => !s);
  const closeDropDown = () => setShowDropDown(false);

  function LogoutUser() {
    dispatch(logout());
    closeDropDown();
  }

  return (
    <ClickAway onClickAway={closeDropDown}>
      <Container>
        <DropdownButton onClick={toggleDropdown}>
          <Avatar>
            {props?.avatar ? (
              <img src={props.avatar} alt={props.fullName} />
            ) : (
              <P medium semiBold>
                {props.fullName?.charAt(0).toUpperCase()}
              </P>
            )}
          </Avatar>
          <UserName little semiBold>
            {props.fullName}
          </UserName>
        </DropdownButton>
        {showDropdown && (
          <>
            <div className="triangle" />

            <Dropdown>
              <button type="button" onClick={LogoutUser}>
                Log out
              </button>

              <div className="links">
                <a
                  href={`${
                    process.env.REACT_APP_AUTH_APP_URL
                  }/profile?redirected_user_email=${encodeURIComponent(
                    props?.email || ""
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Profile
                </a>
                <a
                  href="https://support.busha.co"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Busha help center
                </a>
                <a
                  href="https://learn.busha.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
                <a
                  href="https://t.me/getbusha"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join Community
                </a>
              </div>
            </Dropdown>
          </>
        )}
      </Container>
    </ClickAway>
  );
};

export default UserDropdown;
