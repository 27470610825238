import { mapKeys } from 'app/utils/helpers';
import { GenericAction } from '../types';
import { fetchStories, StoriesState } from './types';

const INITIAL_STATE: StoriesState = {
	error: null,
	status: 'idle',
	data: null,
	meta: null,
};

export default function storiesReducer(state = INITIAL_STATE, action: GenericAction): StoriesState {
	switch (action.type) {
		case fetchStories.pending:
			return {
				...state,
				error: null,
				status: 'fetching',
			};

		case fetchStories.rejected:
			return {
				...state,
				error: action.payload,
				status: 'error',
			};

		case fetchStories.fulfilled:
			return {
				...state,
				error: null,
				status: 'fetched',
				data: { ...state.data, ...mapKeys(action.payload.data, 'id') },
				meta: action.payload.meta,
			};

		default:
			return state;
	}
}
