import * as React from "react";
import * as Sentry from "@sentry/react";

import LoaderContainer from "./LoaderContainer";

interface Props {
  location?: string;
}
const ErrorBoundary = (props: React.PropsWithChildren<Props>) => {
  const { location = "app" } = props;
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("location", location);
      }}
      fallback={({ resetError }) => (
        <LoaderContainer
          errorMessage="Oops! Something went wrong"
          error
          errorControlOnClick={resetError}
          errorControlClickText="Click here to reset!"
        />
      )}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
