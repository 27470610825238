import {
  WalletsState,
  fetchWallets,
  fetchWallet,
  fetchInactiveWallets,
  createWallet,
  fetchWalletPossibleActions,
} from "./types";

const INITIAL_STATE: WalletsState = {
  error: null,
  dataStatus: "idle",
  actionStatus: "idle",
  data: {
    wallets: {},
    inactiveWallets: [],
    walletsPossibleActions: {},
  },
};

export default function wallets(
  state = INITIAL_STATE,
  action: any
): WalletsState {
  switch (action.type) {
    case fetchWallets.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchWallets.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchWallets.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: { ...state.data, wallets: action.payload },
      };

    case fetchWallet.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchWallet.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchWallet.fulfilled: {
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          wallets: {
            ...state.data.wallets,
            [action.payload.currency]: action.payload,
          },
        },
      };
    }

    case fetchInactiveWallets.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchInactiveWallets.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchInactiveWallets.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: { ...state.data, inactiveWallets: action.payload },
      };

    case createWallet.pending:
      return {
        ...state,
        actionStatus: "creating",
        error: null,
      };

    case createWallet.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case createWallet.fulfilled:
      return {
        ...state,
        actionStatus: "created",
        error: null,
      };

    case fetchWalletPossibleActions.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchWalletPossibleActions.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchWalletPossibleActions.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          walletsPossibleActions: {
            ...state.data.walletsPossibleActions,
            [action.payload.id]: action.payload,
          },
        },
      };

    default:
      return state;
  }
}
