import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";
import {
  FetchReferralsResponse,
  FetchReferralSummaryResponse,
  ReferralListTypes,
} from "app/api/referral/types";

export interface ReferralState {
  data: {
    summary: FetchReferralSummaryResponse | null;
    signups: FetchReferralsResponse | null;
    pending: FetchReferralsResponse | null;
    earned: FetchReferralsResponse | null;
  };
  dataStatus: ReducerStatusType;
  actionStatus: ReducerStatusType;
  error: string | null;
}

export const fetchReferrals = createActionType("fetch_referrals");
export const fetchReferralSummary = createActionType("fetch_referral_summary");
export const redeemReferralCode = createActionType("redeem_referral_code");
export const clearReferralError = createActionType("clear_referral_error");

export interface FetchReferralsAction {
  type: string;
  payload: {
    listType: ReferralListTypes;
  };
}
export interface RedeemReferralCodeAction {
  type: string;
  payload: {
    code: string;
  };
}
