import {
  AddressState,
  fetchAddresses,
  createAddress,
  resetAddressAction,
  clearAddressError,
} from "./types";

const INITIAL_STATE: AddressState = {
  error: null,
  dataStatus: "idle",
  actionStatus: "idle",
  data: {},
};

export default function addresses(
  state = INITIAL_STATE,
  action: any
): AddressState {
  switch (action.type) {
    case fetchAddresses.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchAddresses.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchAddresses.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: { [action.payload.asset]: action.payload.data || [] },
      };

    case createAddress.pending:
      return {
        ...state,
        actionStatus: "creating",
        error: null,
      };

    case createAddress.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case createAddress.fulfilled:
      return {
        ...state,
        actionStatus: "created",
        error: null,
        data: {
          [action.payload.asset]: [
            action.payload,
            ...state.data[action.payload.asset],
          ],
        },
      };

    case resetAddressAction.default: {
      return {
        ...state,
        actionStatus: "idle",
      };
    }

    case clearAddressError.default: {
      return {
        ...state,
        error: null,
        dataStatus: state.dataStatus === "error" ? "idle" : state.dataStatus,
        actionStatus:
          state.actionStatus === "error" ? "idle" : state.actionStatus,
      };
    }

    default:
      return state;
  }
}
