import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from "axios";

import { FetchStoriesResponse } from "app/api/stories/types";
import parseError from "app/lib/parseError";
import { fetchStories, FetchStoriesAction } from "./types";
import api from "app/api";

function* fetchStoriesSaga({ payload }: FetchStoriesAction) {
  try {
    yield put({ type: fetchStories.pending });
    const res: AxiosResponse<FetchStoriesResponse> = yield call(
      api.storiesService.fetchStories,
      payload
    );
    yield put({ type: fetchStories.fulfilled, payload: res.data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchStories.rejected, payload: errorMessage });
  }
}

export default function* allStoriesSaga() {
	yield all([takeLatest(fetchStories.default, fetchStoriesSaga)]);
}
