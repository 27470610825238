import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import {
  InitializeTransferAction,
  initializeTransfer,
  FinalizeTransferAction,
  finalizeTransfer,
  ResendTransferCodeAction,
  resendTransferCode,
  ValidateDestinationAction,
  validateDestination,
  fetchWithdrawalRequirements,
  FetchTransferMethodsAction,
  fetchTransferMethods,
} from "./types";
import {
  InitializeTransferResponse,
  FinalizeTransferResponse,
  ResendTransferCodeResponse,
  ValidateDestinationResponse,
  FetchWithdrawalRequirementsResponse,
  FetchTransferMethodsResponse,
} from "app/api/transfer/types";
import { notify } from "app/utils/toast";
import { mapKeys } from "app/utils/helpers";

function* initializeTransferSaga(action: InitializeTransferAction) {
  try {
    yield put({ type: initializeTransfer.pending });

    const { payload } = action;

    const res: AxiosResponse<InitializeTransferResponse> = yield call(
      api.transferService.initializeTransfer,
      payload
    );
    const data = res.data;
    yield put({ type: initializeTransfer.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError, {
      useErrorResponse: true,
    });
    yield call(notify, errorMessage?.error?.message ?? "Something went wrong", {
      variant: "error",
    });
    yield put({ type: initializeTransfer.rejected, payload: errorMessage });
  }
}

function* finalizeTransferSaga(action: FinalizeTransferAction) {
  try {
    yield put({ type: finalizeTransfer.pending });

    const { payload } = action;

    const res: AxiosResponse<FinalizeTransferResponse> = yield call(
      api.transferService.finalizeTransfer,
      payload
    );
    const data = res.data;
    yield put({ type: finalizeTransfer.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError, {
      useErrorResponse: true,
    });
    yield call(notify, errorMessage?.error?.message ?? "Something went wrong", {
      variant: "error",
    });
    yield put({ type: finalizeTransfer.rejected, payload: errorMessage });
  }
}

function* resendTransferCodeSaga(action: ResendTransferCodeAction) {
  try {
    yield put({ type: resendTransferCode.pending });

    const { token } = action.payload;

    const res: AxiosResponse<ResendTransferCodeResponse> = yield call(
      api.transferService.resendTransferCode,
      token
    );
    const data = res.data;
    yield put({ type: resendTransferCode.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: resendTransferCode.rejected, payload: errorMessage });
  }
}

function* validateDestinationSaga(action: ValidateDestinationAction) {
  try {
    yield put({ type: validateDestination.pending });

    const { payload } = action;

    const res: AxiosResponse<ValidateDestinationResponse> = yield call(
      api.transferService.validateDestination,
      payload
    );
    const data = res.data;
    yield put({ type: validateDestination.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError, {
      useErrorResponse: true,
    });
    yield call(notify, errorMessage?.error?.message ?? "Something went wrong", {
      variant: "error",
    });
    yield put({ type: validateDestination.rejected, payload: errorMessage });
  }
}

function* fetchWithdrawalRequirementsSaga() {
  try {
    yield put({ type: fetchWithdrawalRequirements.pending });

    const res: AxiosResponse<FetchWithdrawalRequirementsResponse> = yield call(
      api.transferService.fetchWithdrawalRequirements
    );
    const { data } = res;
    yield put({
      type: fetchWithdrawalRequirements.fulfilled,
      payload: mapKeys(data, "currency_code"),
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({
      type: fetchWithdrawalRequirements.rejected,
      payload: errorMessage,
    });
  }
}

function* fetchTransferMethodsSaga(action: FetchTransferMethodsAction) {
  try {
    yield put({ type: fetchTransferMethods.pending });

    const { currencyCode } = action.payload;

    const res: AxiosResponse<FetchTransferMethodsResponse> = yield call(
      api.transferService.fetchTransferMethods,
      currencyCode
    );
    const { data } = res;
    yield put({ type: fetchTransferMethods.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchTransferMethods.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(initializeTransfer.default, initializeTransferSaga),
    takeLatest(finalizeTransfer.default, finalizeTransferSaga),
    takeLatest(resendTransferCode.default, resendTransferCodeSaga),
    takeLatest(validateDestination.default, validateDestinationSaga),
    takeLatest(
      fetchWithdrawalRequirements.default,
      fetchWithdrawalRequirementsSaga
    ),
    takeLatest(fetchTransferMethods.default, fetchTransferMethodsSaga),
  ]);
}
