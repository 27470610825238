import {
  all,
  call,
  put,
  takeLatest,
  takeEvery,
} from "@redux-saga/core/effects";
import { AxiosError, AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import api from "app/api";
import {
  CreateTradeResponse,
  GetAllTradesResponse,
  GetDisputeResponse,
  GetSingleTradeResponse,
  SetTradStatusResponse,
  SummonTraderResponse,
  ExportTradesResponse,
} from "app/api/trades/types";
import parseError from "app/lib/parseError";
import { mapKeys, formatDateStr } from "app/utils/helpers";
import { notify } from "app/utils/toast";
import { GenericResponse } from "app/api/types";
import {
  createTrade,
  CreateTradeAction,
  getAllTrades,
  GetAllTradesAction,
  getSingleTrade,
  GetSingleTradeAction,
  setTradeStatus,
  SetTradeStatusAction,
  SummonTraderAction,
  summonTrader,
  CreateDisputeAction,
  createDispute,
  getSingleDispute,
  GetSingleDisputeAction,
  createReview,
  CreateReviewAction,
  exportTrades,
  ExportTradesAction,
} from "./types";

function* getAllTradesSaga({ payload }: GetAllTradesAction) {
  try {
    yield put({ type: getAllTrades.pending });
    const res: AxiosResponse<GetAllTradesResponse> = yield call(
      api.tradesService.getAllTrades,
      payload
    );

    yield put({
      type: getAllTrades.fulfilled,
      payload: { data: mapKeys(res.data.data, "id"), meta: res.data.meta },
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: getAllTrades.rejected, payload: errorMessage });
  }
}

function* getSingleTradeSaga({ payload }: GetSingleTradeAction) {
  try {
    yield put({ type: getSingleTrade.pending });
    const res: AxiosResponse<GetSingleTradeResponse> = yield call(
      api.tradesService.getSingleTrade,
      payload
    );
    yield put({ type: getSingleTrade.fulfilled, payload: res.data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: getSingleTrade.rejected, payload: errorMessage });
  }
}

function* createTradeSaga({ payload }: CreateTradeAction) {
  try {
    yield put({ type: createTrade.pending });
    const res: AxiosResponse<CreateTradeResponse> = yield call(
      api.tradesService.createTrade,
      payload
    );
    yield put({ type: createTrade.fulfilled, payload: res.data });
    yield call(notify, res.data.message || "Trade created successfully", {
      variant: "success",
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: createTrade.rejected, payload: errorMessage });
  }
}

function* setTradeStatusSaga({ payload }: SetTradeStatusAction) {
  try {
    yield put({ type: setTradeStatus.pending });
    const res: AxiosResponse<SetTradStatusResponse> = yield call(
      api.tradesService.setTradeStatus,
      payload
    );
    yield put({ type: setTradeStatus.fulfilled, payload: res.data });

    yield call(
      notify,
      res.data.message || "Trade status updated successfully",
      { variant: "success" }
    );
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });

    yield put({ type: setTradeStatus.rejected, payload: errorMessage });
  }
}

function* summonTraderSaga({ payload }: SummonTraderAction) {
  try {
    yield put({ type: summonTrader.pending });
    const res: AxiosResponse<SummonTraderResponse> = yield call(
      api.tradesService.summonTrader,
      payload
    );

    yield call(notify, res.data.message || "Trader summoned successfully", {
      variant: "success",
    });

    yield put({ type: summonTrader.fulfilled });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });

    yield put({ type: summonTrader.rejected, payload: errorMessage });
  }
}

function* createDisputeSaga({ payload }: CreateDisputeAction) {
  try {
    yield put({ type: createDispute.pending });
    const res: AxiosResponse<GenericResponse> = yield call(
      api.tradesService.createDispute,
      payload
    );

    yield call(notify, res.data.message || "Disputed raised", {
      variant: "success",
    });

    yield put({ type: createDispute.fulfilled });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });

    yield put({ type: createDispute.rejected, payload: errorMessage });
  }
}

function* getSingleDisputeSaga({ payload }: GetSingleDisputeAction) {
  try {
    yield put({ type: getSingleDispute.pending });
    const res: AxiosResponse<GetDisputeResponse> = yield call(
      api.tradesService.getDispute,
      payload
    );

    yield put({ type: getSingleDispute.fulfilled, payload: res.data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: getSingleDispute.rejected, payload: errorMessage });
  }
}

function* createReviewSaga({ payload }: CreateReviewAction) {
  try {
    yield put({ type: createReview.pending });
    const res: AxiosResponse<GenericResponse> = yield call(
      api.tradesService.createReview,
      payload
    );

    yield call(notify, res.data.message || "Review submitted", {
      variant: "success",
    });

    yield put({ type: createReview.fulfilled });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });

    yield put({ type: createReview.rejected, payload: errorMessage });
  }
}

function* exportTradesSaga(action: ExportTradesAction) {
  try {
    yield put({ type: exportTrades.pending });

    const { payload } = action;

    const res: AxiosResponse<ExportTradesResponse> = yield call(
      api.tradesService.exportTrades,
      payload
    );

    const { data: file } = res;

    const startDate = formatDateStr(payload.start.toDateString());
    const endDate = formatDateStr(payload.end.toDateString());
    const filename = `Busha Trades statement [${startDate}-${endDate}].xls`;

    yield call(saveAs, file, filename);

    yield put({ type: exportTrades.fulfilled });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    const { payload } = action;

    yield call(notify, `Could not export trades for ${payload.currency}`, {
      variant: "error",
    });
    yield put({
      type: exportTrades.rejected,
      payload: errorMessage,
    });
  }
}

export default function* tradesSaga() {
  yield all([
    takeLatest(getAllTrades.default, getAllTradesSaga),
    takeEvery(getSingleTrade.default, getSingleTradeSaga),
    takeLatest(createTrade.default, createTradeSaga),
    takeLatest(setTradeStatus.default, setTradeStatusSaga),
    takeLatest(summonTrader.default, summonTraderSaga),
    takeLatest(createDispute.default, createDisputeSaga),
    takeLatest(getSingleDispute.default, getSingleDisputeSaga),
    takeLatest(createReview.default, createReviewSaga),
    takeLatest(exportTrades.default, exportTradesSaga),
  ]);
}
