import styled from "styled-components";
import media from "app/styles/utils/media";

const Content = styled.section`
  margin-left: 240px;
  padding-left: 64px;
  height: 100%;
  overflow: auto;

  ${media.smallDesktop`
    margin-left: 0;
    padding: 0px 82px;
    width: 100%;
  `};

  ${media.tablet`
    margin-left: 0;
    padding: 0px 12px;
    width: 100%;
  `};

  ${media.mobile`
    padding: 0px;
  `};

  .page-content {
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    margin-bottom: 100px;
    .loader {
      height: 300px;
    }
  }
`;

export default Content;
