import { TokensState, setTokens } from "./types";
import storage from "app/lib/storage";

const cachedTokens = storage.get("tokens");

const INITIAL_STATE: TokensState = {
  ...cachedTokens,
};

export default function tokens(state = INITIAL_STATE, action: any): TokensState {
  switch (action.type) {
    case setTokens.fulfilled:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
