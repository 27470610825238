import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";
import { AddBankAccountPayload } from "../../api/bank/types";

export interface Bank {
  id: string | number;
  name: string;
  code: string;
}

export interface BankAccount {
  id: string;
  created_at: string;
  user_id: string;
  name: string;
  code: string;
  country: string;
  account_name: string;
  account_number: string;
  recipient: string;
  blacklist: boolean;
}

export interface BanksState {
  data: {
    banks: Bank[];
    accounts: BankAccount[];
  };
  dataStatus: ReducerStatusType;
  actionStatus: ReducerStatusType;
  error: string | null;
}

export const fetchBanks = createActionType("fetch_banks");
export const fetchBankAccounts = createActionType("fetch_bank_accounts");
export const addBankAccount = createActionType("add_bank_account");
export const deleteBankAccount = createActionType("delete_bank_account");

export interface FetchBanksAction {
  type: string;
  payload: {
    countryCode: string;
  };
}

export interface AddBankAccountAction {
  type: string;
  payload: AddBankAccountPayload;
}

export interface DeleteBankAccountAction {
  type: string;
  payload: {
    bankAccountId: string;
  };
}
