import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest, takeEvery } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import {
  initializeOrder,
  finalizeOrder,
  fetchConvertOrderBooks,
  fetchTradeOrderBooks,
  cancelOrder,
  fetchRecurringOrders,
  fetchRecurringOrder,
  createRecurringOrder,
  cancelRecurringOrder,
  CancelOrderAction,
  InitializeOrderAction,
  FinalizeOrderAction,
  FetchTradeOrderBooksAction,
  FetchConvertOrderBooksAction,
  FetchRecurringOrderAction,
  CancelRecurringOrderAction,
  CreateRecurringOrderAction,
} from "./types";
import {
  InitializeOrderResponse,
  CancelOrderResponse,
  FetchTradeOrderBookResponse,
  FinalizeOrderResponse,
  FetchConvertOrderBookResponse,
  FetchRecurringOrdersResponse,
  FetchRecurringOrderResponse,
  CreateRecurringOrderResponse,
  CancelRecurringOrderResponse,
} from "app/api/order/types";
import { mapKeys } from "app/utils/helpers";
import { notify } from "app/utils/toast";

function* fetchTradeOrderBooksSaga(action: FetchTradeOrderBooksAction) {
  try {
    yield put({ type: fetchTradeOrderBooks.pending });

    const { currencyCode } = action.payload;

    const res: AxiosResponse<FetchTradeOrderBookResponse> = yield call(
      api.orderService.fetchTradeOrderBooks,
      currencyCode
    );
    const data = res.data;
    yield put({
      type: fetchTradeOrderBooks.fulfilled,
      payload: { currencyCode, data },
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchTradeOrderBooks.rejected, payload: errorMessage });
  }
}

function* fetchConvertOrderBooksSaga(action: FetchConvertOrderBooksAction) {
  try {
    yield put({ type: fetchConvertOrderBooks.pending });

    const { currencyCode } = action.payload;

    const res: AxiosResponse<FetchConvertOrderBookResponse> = yield call(
      api.orderService.fetchConvertOrderBooks,
      currencyCode
    );
    const data = res.data;
    yield put({
      type: fetchConvertOrderBooks.fulfilled,
      payload: { currencyCode, data },
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchConvertOrderBooks.rejected, payload: errorMessage });
  }
}

function* initializeOrderSaga(action: InitializeOrderAction) {
  try {
    yield put({ type: initializeOrder.pending });

    const { payload } = action;

    const res: AxiosResponse<InitializeOrderResponse> = yield call(
      api.orderService.initializeOrder,
      payload
    );
    const data = res.data;
    yield put({ type: initializeOrder.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: initializeOrder.rejected, payload: errorMessage });
  }
}

function* finalizeOrderSaga(action: FinalizeOrderAction) {
  try {
    yield put({ type: finalizeOrder.pending });

    const { payload } = action;

    const res: AxiosResponse<FinalizeOrderResponse> = yield call(
      api.orderService.finalizeOrder,
      payload
    );
    const data = res.data;
    yield put({ type: finalizeOrder.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: finalizeOrder.rejected, payload: errorMessage });
  }
}

function* cancelOrderSaga(action: CancelOrderAction) {
  try {
    yield put({ type: cancelOrder.pending });

    const { orderId } = action.payload;

    const res: AxiosResponse<CancelOrderResponse> = yield call(
      api.orderService.cancelOrder,
      orderId
    );
    const data = res.data;
    yield put({ type: cancelOrder.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: cancelOrder.rejected, payload: errorMessage });
  }
}

function* fetchRecurringOrdersSaga() {
  try {
    yield put({ type: fetchRecurringOrders.pending });

    const res: AxiosResponse<FetchRecurringOrdersResponse> = yield call(
      api.orderService.fetchRecurringOrders
    );
    const data = res.data;
    yield put({
      type: fetchRecurringOrders.fulfilled,
      payload: mapKeys(data?.recurring_orders || [], "id"),
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchRecurringOrders.rejected, payload: errorMessage });
  }
}

function* fetchRecurringOrderSaga(action: FetchRecurringOrderAction) {
  try {
    yield put({ type: fetchRecurringOrder.pending });

    const { orderId } = action.payload;

    const res: AxiosResponse<FetchRecurringOrderResponse> = yield call(
      api.orderService.fetchRecurringOrder,
      orderId
    );
    const data = res.data;
    yield put({ type: fetchRecurringOrder.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchRecurringOrder.rejected, payload: errorMessage });
  }
}

function* createRecurringOrderSaga(action: CreateRecurringOrderAction) {
  try {
    yield put({ type: createRecurringOrder.pending });

    const { payload } = action;

    const res: AxiosResponse<CreateRecurringOrderResponse> = yield call(
      api.orderService.createRecurringOrder,
      payload
    );
    const data = res.data;
    yield put({ type: createRecurringOrder.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: createRecurringOrder.rejected, payload: errorMessage });
  }
}

function* cancelRecurringOrderSaga(action: CancelRecurringOrderAction) {
  try {
    yield put({ type: cancelRecurringOrder.pending });

    const { orderId } = action.payload;

    const res: AxiosResponse<CancelRecurringOrderResponse> = yield call(
      api.orderService.cancelRecurringOrder,
      orderId
    );
    const data = res.data;
    yield call(notify, "Recurring order cancelled successfully", {
      variant: "success",
    });
    yield put({ type: cancelRecurringOrder.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: cancelRecurringOrder.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeEvery(fetchTradeOrderBooks.default, fetchTradeOrderBooksSaga),
    takeEvery(fetchConvertOrderBooks.default, fetchConvertOrderBooksSaga),
    takeLatest(initializeOrder.default, initializeOrderSaga),
    takeLatest(finalizeOrder.default, finalizeOrderSaga),
    takeLatest(cancelOrder.default, cancelOrderSaga),
    takeLatest(fetchRecurringOrders.default, fetchRecurringOrdersSaga),
    takeLatest(fetchRecurringOrder.default, fetchRecurringOrderSaga),
    takeLatest(createRecurringOrder.default, createRecurringOrderSaga),
    takeLatest(cancelRecurringOrder.default, cancelRecurringOrderSaga),
  ]);
}
