import request from "../request";
import { GenericResponse } from "../types";
import {
  CreateAddressResponse,
  CreateAddressPayload,
  FetchAddressesResponse,
  GetChainAddressesResponse,
  GetAddressesResponse,
  CreateAddressV2Payload,
} from "./types";

const fetchAddress = (asset: string) => {
  return request.get<FetchAddressesResponse>(`/v1.1/address/${asset}`);
};
const createAddress = (payload: CreateAddressPayload) => {
  return request.post<CreateAddressResponse>(`/v1.1/address?new=true`, payload);
};

export const fetchChainAddresses = (asset: string) => {
  return request.get<GetChainAddressesResponse>(`/v1.1/addresses/${asset}`);
};

export const fetchAddresses = (asset: string, chain?: string) => {
  return request.get<GetAddressesResponse>(`/v1.1/addresses/${asset}/all`, {
    params: chain ? { chain } : undefined,
  });
};

const baseRequestOptionV2 = {
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
};

export const createAddressesV2 = (data: CreateAddressV2Payload) => {
  return request.post<GenericResponse>(
    `/v1/deposit/addresses`,
    data,
    baseRequestOptionV2
  );
};

export const fetchAddressesV2 = (asset: string, chain?: string) => {
  return request.get<GetAddressesResponse>(
    `/v1/deposit/addresses/${asset}/all`,
    {
      ...baseRequestOptionV2,
      params: chain ? { chain } : undefined,
    }
  );
};

const addressService = {
  fetchAddress,
  createAddress,
  fetchChainAddresses,
  fetchAddresses,
  createAddressesV2,
  fetchAddressesV2,
};
export default addressService;
