import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest, takeEvery } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import {
  fetchWallets,
  fetchWallet,
  fetchInactiveWallets,
  createWallet,
  FetchWalletAction,
  CreateWalletAction,
  FetchWalletPossibleAction,
  fetchWalletPossibleActions,
} from "./types";
import {
  FetchWalletsResponse,
  FetchWalletResponse,
  FetchInactiveWalletsResponse,
  FetchWalletPossibleActionsResponse,
} from "app/api/wallet/types";
import { mapKeys } from "app/utils/helpers";
import { notify } from "app/utils/toast";

function* fetchWalletsSaga() {
  try {
    yield put({ type: fetchWallets.pending });

    const res: AxiosResponse<FetchWalletsResponse> = yield call(
      api.walletService.fetchWallets
    );
    const { data } = res.data;
    yield put({
      type: fetchWallets.fulfilled,
      payload: mapKeys(data, "currency"),
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchWallets.rejected, payload: errorMessage });
  }
}

function* fetchWalletSaga(action: FetchWalletAction) {
  try {
    const { payload } = action;
    yield put({ type: fetchWallet.pending });

    const res: AxiosResponse<FetchWalletResponse> = yield call(
      api.walletService.fetchWallet,
      payload
    );
    const data = res.data;
    yield put({ type: fetchWallet.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchWallet.rejected, payload: errorMessage });
  }
}

function* fetchInactiveWalletsSaga() {
  try {
    yield put({ type: fetchInactiveWallets.pending });

    const res: AxiosResponse<FetchInactiveWalletsResponse> = yield call(
      api.walletService.fetchInactiveWallets
    );
    const { data } = res;
    yield put({
      type: fetchInactiveWallets.fulfilled,
      payload: data,
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchInactiveWallets.rejected, payload: errorMessage });
  }
}

function* createWalletSaga(action: CreateWalletAction) {
  try {
    const { wallet } = action.payload;
    yield put({ type: createWallet.pending });

    yield call(api.walletService.createWallet, wallet);
    yield call(notify, `${wallet} wallet created`, { variant: "success" });
    yield put({ type: createWallet.fulfilled });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield call(notify, errorMessage, { variant: "error" });
    yield put({ type: createWallet.rejected, payload: errorMessage });
  }
}

function* fetchWalletsPossibleActionsSaga({
  payload,
}: FetchWalletPossibleAction) {
  try {
    yield put({ type: fetchWalletPossibleActions.pending });
    const response: AxiosResponse<FetchWalletPossibleActionsResponse> =
      yield call(
        api.walletService.fetchWalletPossibleActions,
        payload.walletId.toLowerCase()
      );
    yield put({
      type: fetchWalletPossibleActions.fulfilled,
      payload: { id: payload.walletId, ...response.data },
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({
      type: fetchWalletPossibleActions.rejected,
      payload: errorMessage,
    });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchWallets.default, fetchWalletsSaga),
    takeEvery(fetchWallet.default, fetchWalletSaga),
    takeLatest(fetchInactiveWallets.default, fetchInactiveWalletsSaga),
    takeLatest(createWallet.default, createWalletSaga),
    takeLatest(
      fetchWalletPossibleActions.default,
      fetchWalletsPossibleActionsSaga
    ),
  ]);
}
