import {
  ReferralState,
  fetchReferrals,
  fetchReferralSummary,
  redeemReferralCode,
  clearReferralError,
} from "./types";

const INITIAL_STATE: ReferralState = {
  error: null,
  actionStatus: "idle",
  dataStatus: "idle",
  data: {
    summary: null,
    signups: null,
    pending: null,
    earned: null,
  },
};

export default function referrals(state = INITIAL_STATE, action: any): ReferralState {
  switch (action.type) {
    case fetchReferrals.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchReferrals.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchReferrals.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          [action.listType]: action.payload,
        },
      };

    case fetchReferralSummary.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchReferralSummary.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchReferralSummary.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          summary: action.payload,
        },
      };

    case redeemReferralCode.pending:
      return {
        ...state,
        actionStatus: "verifying",
        error: null,
      };

    case redeemReferralCode.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case redeemReferralCode.fulfilled:
      return {
        ...state,
        actionStatus: "verified",
        error: null,
      };

    case clearReferralError.fulfilled:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}
