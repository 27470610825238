import queryString from 'query-string';

import request from '../request';
import { FetchStoriesPayload, FetchStoriesResponse } from './types';

const fetchStories = async (payload: FetchStoriesPayload) => {
	const query = queryString.stringify(payload);
	return await request.get<FetchStoriesResponse>(`/v1.1/stories/?${query}`);
};

const storiesService = { fetchStories };

export default storiesService;
