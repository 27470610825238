import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";

import profileService from "app/api/profile";
import parseError from "app/lib/parseError";
import { UserProfile } from "app/models/profile";
import {
  ProfileContext,
  SetProfileContext,
} from "app/providers/ProfileProvider";

const useGetUserProfile = () => {
  const profile = React.useContext(ProfileContext);

  const setProfile = React.useContext(SetProfileContext);

  const handleSuccess = (res: AxiosResponse<UserProfile>) => {
    setProfile(res.data);
  };

  const { isLoading, mutate, error } = useMutation(
    () => profileService.fetchProfileV2(),
    {
      onSuccess: handleSuccess,
    }
  );

  return {
    isLoading,
    profile,
    fetchProfile: mutate,
    error: error ? parseError(error as AxiosError) : undefined,
  };
};

export default useGetUserProfile;
