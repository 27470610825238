import qs from "query-string";
import { format as formatDate } from "date-fns";

import request from "../request";
import { GenericResponse } from "../types";
import {
  CreateReviewPayload,
  CreateTradePayload,
  CreateTradeResponse,
  GetAllTradesPayload,
  GetAllTradesResponse,
  GetSingleTradePayload,
  GetSingleTradeResponse,
  SetTradeStatusPayload,
  SetTradStatusResponse,
  SummonTraderPayload,
  SummonTraderResponse,
  CreateDisputePayload,
  GetDisputeResponse,
  ExportTradesPayload,
  ExportTradesResponse,
  CreateConnectTradePayload,
  CreateConnectTradeResponse,
  FetchConnectTradeResponse,
  GetUserStatResponse,
  UpdateConnectTradePayload,
  RaiseConnectTradeDisputePayload,
  ReviewConnectTradePayload,
  FetchConnectTradesQuery,
  FetchConnectTradesResponse,
} from "./types";

const createTrade = (payload: CreateTradePayload) => {
  return request.post<CreateTradeResponse>(`/v1.1/trades`, payload);
};

const getSingleTrade = (payload: GetSingleTradePayload) => {
  return request.get<GetSingleTradeResponse>(
    `/v1.1/trades/patch/${payload.id}`
  );
};

const getAllTrades = (payload: GetAllTradesPayload) => {
  const query = qs.stringify(payload, {
    arrayFormat: "comma",
    skipEmptyString: true,
    skipNull: true,
  });
  return request.get<GetAllTradesResponse>(`/v1.1/trades/patch?${query}`);
};

const setTradeStatus = ({ id, ...payload }: SetTradeStatusPayload) => {
  return request.put<SetTradStatusResponse>(
    `/v1.1/trades/${id}/status`,
    payload
  );
};

const summonTrader = ({ id }: SummonTraderPayload) => {
  return request.get<SummonTraderResponse>(`/v1.1/trades/${id}/summon`);
};

const createDispute = (payload: CreateDisputePayload) => {
  return request.post<GenericResponse>(`/v1.1/disputes`, payload);
};

const getDispute = (disputeId: string) => {
  return request.get<GetDisputeResponse>(`/v1.1/disputes/${disputeId}`);
};

const createReview = (payload: CreateReviewPayload) => {
  return request.post<GenericResponse>(`/v1.1/reviews`, payload);
};

const exportTrades = (payload: ExportTradesPayload) => {
  const { asset, start, end, settled, currency } = payload;
  const query = qs.stringify(
    {
      start: formatDate(start, "yyyy-MM-dd"),
      end: formatDate(end, "yyyy-MM-dd"),
      asset,
      settled,
    },
    {
      skipEmptyString: true,
      skipNull: true,
    }
  );
  return request.get<ExportTradesResponse>(
    `/v1.1/trades/${currency}/statement?${query}`,
    { responseType: "blob" }
  );
};

export const createConnectTrade = (payload: CreateConnectTradePayload) => {
  return request.post<CreateConnectTradeResponse>(
    "/v1/connect/trades",
    payload,
    {
      baseURL: process.env.REACT_APP_API_GATEWAY_URL,
    }
  );
};

export const fetchConnectTrade = (id: string) => {
  const qParams = qs.stringify(
    {
      expand: [
        "user",
        "merchant",
        "user_payment_account.fields",
        "merchant_payment_account.fields",
      ],
    },
    { arrayFormat: "none" }
  );

  return request.get<FetchConnectTradeResponse>(`/v1/connect/trades/${id}`, {
    baseURL: process.env.REACT_APP_API_GATEWAY_URL,
    params: new URLSearchParams(qParams),
  });
};

export const getTradeUserStat = (userId: string) => {
  return request.get<GetUserStatResponse>(`/v1/connect/stats/${userId}`, {
    baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  });
};

export const updateConnectTrade = (data: UpdateConnectTradePayload) => {
  return request.put<FetchConnectTradeResponse>(
    `/v1/connect/trades/${data.id}`,
    {
      status: data.status,
    },
    {
      baseURL: process.env.REACT_APP_API_GATEWAY_URL,
    }
  );
};

export const raiseConnectTradeDispute = (
  payload: RaiseConnectTradeDisputePayload
) => {
  return request.post<GenericResponse>(`/v1/connect/disputes`, payload, {
    baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  });
};

export const reviewConnectTrade = ({
  trade_id,
  ...rest
}: ReviewConnectTradePayload) => {
  return request.post<GenericResponse>(
    `/v1/connect/trades/${trade_id}/reviews`,
    rest,
    {
      baseURL: process.env.REACT_APP_API_GATEWAY_URL,
    }
  );
};

export const fetchConnectTrades = ({
  userId,
  ...data
}: FetchConnectTradesQuery) => {
  return request.get<FetchConnectTradesResponse>(`/v1/connect/trades`, {
    baseURL: process.env.REACT_APP_API_GATEWAY_URL,
    params: {
      limit: 5,
      ...data,
      user_id: userId,
    },
  });
};

export const fetchCashConnectTrades = ({
  userId,
  ...data
}: FetchConnectTradesQuery) => {
  return request.get<GetAllTradesResponse>(`/v1.1/trades/patch`, {
    params: {
      limit: 5,
      ...data,
      user_id: userId,
    },
  });
};

const tradesServices = {
  createTrade,
  getSingleTrade,
  getAllTrades,
  setTradeStatus,
  summonTrader,
  createDispute,
  getDispute,
  createReview,
  exportTrades,
  createConnectTrade,
  fetchConnectTrade,
  getTradeUserStat,
  updateConnectTrade,
  raiseConnectTradeDispute,
  reviewConnectTrade,
  fetchConnectTrades,
  fetchCashConnectTrades,
};

export default tradesServices;
