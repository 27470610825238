import styled from "styled-components";

import P from "app/styles/elements/P";
import AAvatar from "app/styles/elements/Avatar";
import media from "app/styles/utils/media";
import Colors from "app/styles/constants/Colors";

export const Container = styled.div`
  position: relative;

  .triangle {
    position: absolute;
    top: 100%;
    right: 15px;
    margin-top: 7px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    border-bottom: none;
    z-index: 1;
  }
`;

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  font-family: "Roobert";
  cursor: pointer;
`;

export const Avatar = styled(AAvatar)`
  margin-right: 6px;
  ${media.mobile`
    margin-right: 0;
  `};
`;

export const UserName = styled(P)`
  color: ${Colors.Grey60};
  ${media.mobile`
        display: none;
    `};
`;

export const Dropdown = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 220px;

  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 12px;
  padding-top: 12px;
  z-index: 1;

  & a,
  & button {
    display: inline-block;
    padding: 12px 20px;
    text-decoration: none;
    cursor: pointer;
    /* font-size: 0.9rem; */
    font-size: 0.8rem;
    color: #1f2933;
    font-family: "Roobert";
    border: none;
    width: 100%;
    text-align: left;
    outline: none;
    &.loading {
      opacity: 0.5;
      cursor: wait;
    }
  }

  .links {
    padding: 12px 0px;
    border-top: 1px solid rgba(211, 213, 216, 0.5);
    margin-top: 9px;
  }
`;
