import React, { ButtonHTMLAttributes } from "react";

import Btn, { ButtonProps } from "app/styles/elements/Button";
import Loader from "./Loader";
import Colors from "app/styles/constants/Colors";

type PartialButtonProps = ButtonProps & ButtonHTMLAttributes<any>;

export interface ButtonComponentProps extends PartialButtonProps {
  text?: string | JSX.Element;
  loading?: boolean;
}

const Button: React.FC<ButtonComponentProps> = (props) => {
  const { text, loading, disabled, ...rest } = props;
  return (
    <Btn disabled={loading || disabled} {...rest}>
      {loading ? (
        <Loader
          size="16"
          stroke="2.5"
          outerColor="transparent"
          innerColor={
            rest.variant === "black" ? Colors.White100 : Colors.Green100
          }
        />
      ) : (
        text || props.children
      )}
    </Btn>
  );
};

export default Button;
