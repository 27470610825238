import { TOKEN_STORAGE_KEY } from "app/constants/variables";
import storage from "app/lib/storage";
import {
  TokenActions,
  TokenContextType,
  TokenDispatch,
  TokensContext,
} from "app/providers/TokensProvider";
import React, { useEffect } from "react";

const useTokens = () => {
  const tokens = React.useContext(TokensContext);
  const dispatch = React.useContext(TokenDispatch);

  const getStorageTokens = (): TokenContextType => {
    let storageTokens = storage.get(TOKEN_STORAGE_KEY);
    return storageTokens;
  };

  useEffect(() => {
    let sTokens = getStorageTokens();

    //TODO this should probably run every defined time

    if (tokens?.access_token !== sTokens?.access_token) {
      dispatch({ type: TokenActions.setTokens, payload: sTokens });
    }
  }, [tokens, dispatch]);

  return {
    access_token: tokens?.access_token,
    refresh_token: tokens?.refresh_token,
  };
};

export default useTokens;
