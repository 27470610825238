import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/lib/parseError";
import {
  FetchMarketPairGraphDataAction,
  fetchMarketPairs,
  fetchMarketPairGraphData,
} from "./types";
import {
  FetchMarketPairGraphDataResponse,
  FetchMarketPairsResponse,
} from "app/api/market/types";
import { mapKeys } from "app/utils/helpers";

function* fetchMarketPairsSaga() {
  try {
    yield put({ type: fetchMarketPairs.pending });

    const res: AxiosResponse<FetchMarketPairsResponse> = yield call(
      api.marketService.fetchMarketPairs
    );
    const { data } = res;
    yield put({
      type: fetchMarketPairs.fulfilled,
      payload: mapKeys(data, "symbol"),
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({ type: fetchMarketPairs.rejected, payload: errorMessage });
  }
}

function* fetchMarketPairGraphDataSaga(action: FetchMarketPairGraphDataAction) {
  try {
    yield put({ type: fetchMarketPairGraphData.pending });

    const { payload } = action;

    const res: AxiosResponse<FetchMarketPairGraphDataResponse> = yield call(
      api.marketService.fetchMarketPairGraphData,
      payload
    );
    const { data } = res;
    yield put({
      type: fetchMarketPairGraphData.fulfilled,
      payload: data,
      meta: payload,
    });
  } catch (error) {
    const errorMessage = parseError(error as AxiosError);
    yield put({
      type: fetchMarketPairGraphData.rejected,
      payload: errorMessage,
    });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchMarketPairs.default, fetchMarketPairsSaga),
    takeLatest(fetchMarketPairGraphData.default, fetchMarketPairGraphDataSaga),
  ]);
}
