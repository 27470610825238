import {
  FetchTradeOrderBookResponse,
  FetchConvertOrderBookResponse,
} from "app/api/order/types";
import {
  OrdersState,
  initializeOrder,
  finalizeOrder,
  fetchTradeOrderBooks,
  fetchConvertOrderBooks,
  fetchRecurringOrders,
  fetchRecurringOrder,
  createRecurringOrder,
  cancelRecurringOrder,
  clearOrder,
  clearOrderError,
} from "./types";

const INITIAL_STATE: OrdersState = {
  error: null,
  dataStatus: "idle",
  actionStatus: "idle",
  data: {
    initializationData: null,
    finalizationData: null,
    createRecurringOrderData: null,
    orderBooks: {
      trade: {},
      convert: {},
    },
    recurringOrders: {},
  },
};

export default function orders(
  state = INITIAL_STATE,
  action: any
): OrdersState {
  switch (action.type) {
    case fetchTradeOrderBooks.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchTradeOrderBooks.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchTradeOrderBooks.fulfilled: {
      const key = action.payload.currencyCode as string;
      const orderBook = action.payload.data as FetchTradeOrderBookResponse;
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          orderBooks: {
            ...state.data.orderBooks,
            trade: {
              ...state.data.orderBooks.trade,
              [key]: orderBook,
            },
          },
        },
      };
    }

    case fetchConvertOrderBooks.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchConvertOrderBooks.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchConvertOrderBooks.fulfilled: {
      const key = action.payload.currencyCode as string;
      const orderBook = action.payload.data as FetchConvertOrderBookResponse;
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          orderBooks: {
            ...state.data.orderBooks,
            convert: {
              ...state.data.orderBooks.convert,
              [key]: orderBook,
            },
          },
        },
      };
    }

    case initializeOrder.pending:
      return {
        ...state,
        actionStatus: "initializing",
        error: null,
      };

    case initializeOrder.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case initializeOrder.fulfilled:
      return {
        ...state,
        dataStatus: "initialized",
        actionStatus: "idle",
        error: null,
        data: {
          ...state.data,
          initializationData: action.payload,
        },
      };

    case finalizeOrder.pending:
      return {
        ...state,
        actionStatus: "finalizing",
        error: null,
      };

    case finalizeOrder.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case finalizeOrder.fulfilled:
      return {
        ...state,
        dataStatus: "finalized",
        actionStatus: "idle",
        error: null,
        data: {
          ...state.data,
          finalizationData: action.payload,
        },
      };

    case fetchRecurringOrders.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchRecurringOrders.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchRecurringOrders.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          recurringOrders: action.payload,
        },
      };

    case fetchRecurringOrder.pending:
      return {
        ...state,
        dataStatus: "fetching",
        error: null,
      };

    case fetchRecurringOrder.rejected:
      return {
        ...state,
        dataStatus: "error",
        error: action.payload,
      };

    case fetchRecurringOrder.fulfilled:
      return {
        ...state,
        dataStatus: "fetched",
        error: null,
        data: {
          ...state.data,
          recurringOrders: {
            ...state.data.recurringOrders,
            [action.payload.id]: action.payload,
          },
        },
      };

    case createRecurringOrder.pending:
      return {
        ...state,
        actionStatus: "creating",
        error: null,
      };

    case createRecurringOrder.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case createRecurringOrder.fulfilled:
      return {
        ...state,
        actionStatus: "created",
        error: null,
        data: {
          ...state.data,
          createRecurringOrderData: action.payload,
        },
      };

    case cancelRecurringOrder.pending:
      return {
        ...state,
        actionStatus: "cancelling",
        error: null,
      };

    case cancelRecurringOrder.rejected:
      return {
        ...state,
        actionStatus: "error",
        error: action.payload,
      };

    case cancelRecurringOrder.fulfilled:
      return {
        ...state,
        actionStatus: "cancelled",
        error: null,
      };

    case clearOrder.fulfilled:
      return {
        ...state,
        data: {
          ...state.data,
          initializationData: null,
          finalizationData: null,
          createRecurringOrderData: null,
        },
      };

    case clearOrderError.fulfilled:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}
