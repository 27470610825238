import request from "../request";
import { GenericResponse } from "../types";
import {
  FetchWalletsResponse,
  FetchWalletResponse,
  FetchInactiveWalletsResponse,
  CreateWalletResponse,
  FetchWalletPossibleActionsResponse,
  FetchWalletsResponseV2,
  FetchWalletResponseV2,
  FetchNewWalletsResponseV2,
} from "./types";

const fetchWallets = async () => {
  return await request.get<FetchWalletsResponse>(`/v1.1/accounts/`);
};

const fetchWallet = async (code: string) => {
  return await request.get<FetchWalletResponse>(`/v1.1/accounts/${code}`);
};

const fetchInactiveWallets = async () => {
  return await request.get<FetchInactiveWalletsResponse>(
    `/v1.1/accounts/inactive`
  );
};

const fetchWalletPossibleActions = async (walletId: string) => {
  return await request.get<FetchWalletPossibleActionsResponse>(
    `/v1/misc/action/${walletId}`
  );
};

const createWallet = async (wallet: string) => {
  return await request.post<CreateWalletResponse>(`/v1.1/accounts/${wallet}`);
};

//V2

const baseRequestOptionV2 = {
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
};

const fetchWalletsV2 = () => {
  return request.get<FetchWalletsResponseV2>(
    `/v1/accounts`,
    baseRequestOptionV2
  );
};

const fetchWalletV2 = (id: string) => {
  return request.get<FetchWalletResponseV2>(
    `/v1/accounts/${id}`,
    baseRequestOptionV2
  );
};

const fetchNewWalletsV2 = () => {
  return request.get<FetchNewWalletsResponseV2>(
    `/v1/accounts/new`,
    baseRequestOptionV2
  );
};

const createNewWalletV2 = (id: string) => {
  return request.post<GenericResponse>(
    `/v1/accounts/${id}`,
    null,
    baseRequestOptionV2
  );
};

const walletService = {
  fetchWallets,
  fetchWallet,
  fetchInactiveWallets,
  createWallet,
  fetchWalletPossibleActions,

  //V2
  fetchWalletsV2,
  fetchWalletV2,
  fetchNewWalletsV2,
  createNewWalletV2,
};

export default walletService;
