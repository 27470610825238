import * as React from "react";
import { match } from "react-router-dom";

import { Container, Link, LinkSection } from "./styles";
import routes, { SideBarLink } from "./routes";
import { stripTrailingSlash } from "app/utils/url";

export const LinkWithChildren: React.FC<{
  link: SideBarLink;
  onClick?: () => void;
}> = ({ link, onClick }) => {
  const [childMatched, setChildMatched] = React.useState(false);

  const isLinkActive = (match: match<{}> | null) => {
    if (!match) {
      Promise.resolve().then(() => setChildMatched(false));
      return false;
    }

    Promise.resolve().then(() => setChildMatched(true));
    return true;
  };

  return (
    <LinkSection>
      {/* <Link className="parent-link" to={link.path} activeClassName="active"> */}
      <Link
        className={`parent-link${childMatched ? " active" : ""}`}
        to={link.path}
        activeClassName="active"
      >
        {link.name}
        {link.isBeta && <sup>beta</sup>}
      </Link>
      <div className="child-links">
        {link.children &&
          link.children.map((r) => (
            <Link
              key={r.name}
              to={
                r.usePath
                  ? r.path
                  : stripTrailingSlash(`${link.path}/${r.path}`)
              }
              activeClassName="active"
              exact={r.exact}
              onClick={onClick}
              isActive={r.usePath ? isLinkActive : undefined}
            >
              {r.name}
            </Link>
          ))}
      </div>
    </LinkSection>
  );
};

const Sidebar: React.FC = (props) => {
  return (
    <Container>
      {routes.map((r) =>
        !r.children ? (
          <Link key={r.name} to={r.path}>
            {r.name}
            {r.isBeta && <sup>beta</sup>}
          </Link>
        ) : (
          <LinkWithChildren key={r.name} link={r} />
        )
      )}
    </Container>
  );
};

export default Sidebar;
