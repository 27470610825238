import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";
import {
  InitializeTransferPayload,
  FinalizeTransferPayload,
  InitializeTransferResponse,
  FinalizeTransferResponse,
  ValidateDestinationPayload,
  ValidateDestinationResponse,
  FetchTransferMethodsResponse,
  WithdrawalRequirementV2,
} from "app/api/transfer/types";

export interface TransfersState {
  data: {
    initializationData: InitializeTransferResponse | null;
    finalizationData: FinalizeTransferResponse | null;
    validationData: ValidateDestinationResponse | null;
    withdrawalRequirements: { [key: string]: WithdrawalRequirementV2 };
    transferMethods: FetchTransferMethodsResponse;
  };
  dataStatus: ReducerStatusType;
  actionStatus: ReducerStatusType;
  error: string | null;
}

export const initializeTransfer = createActionType("initialize_transfer");
export const finalizeTransfer = createActionType("finalize_transfer");
export const resendTransferCode = createActionType("resend_transfer_code");
export const validateDestination = createActionType("validate_destination");
export const fetchWithdrawalRequirements = createActionType(
  "fetch_withdrawal_requirements"
);
export const fetchTransferMethods = createActionType("fetch_transfer_methods");
export const clearTransfer = createActionType("clear_transfer");
export const clearTransferError = createActionType("clear_transfer_error");

export interface InitializeTransferAction {
  type: string;
  payload: InitializeTransferPayload;
}

export interface FinalizeTransferAction {
  type: string;
  payload: FinalizeTransferPayload;
}

export interface ResendTransferCodeAction {
  type: string;
  payload: {
    token: string;
  };
}

export interface FetchTransferMethodsAction {
  type: string;
  payload: {
    currencyCode: string;
  };
}

export interface ValidateDestinationAction {
  type: string;
  payload: ValidateDestinationPayload;
}
