import styled, { css } from "styled-components";
import Colors from "../constants/Colors";

export interface ButtonProps {
  fullwidth?: boolean;
  variant?: "black" | "clear" | "outline" | "success" | "danger";
  medium?: boolean;
  small?: boolean;
}

const Button = styled.button<ButtonProps>`
  height: 54px;
  /* width: 180px; */
  padding: 0px 54px;
  border: none;
  border-radius: 40px;
  transition: background-color 300ms ease-in;
  text-decoration: none;
  font-family: "Roobert";
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;

  & > * {
    margin: 0 5px;
  }

  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `};

  ${(props) =>
    props.medium &&
    css`
      font-size: 0.8rem;
    `};

  ${(props) =>
    props.small &&
    css`
      font-size: 0.7rem;
      height: 36px;
      padding: 0px 16px;
      /* width: 82px; */
    `};

  ${(props) => {
    switch (props.variant) {
      case "black":
        return css`
          background-color: ${Colors.Black100};
          color: ${Colors.White100};
          border: 1px solid transparent;

          &:disabled {
            background: rgba(0, 0, 0, 0.75);
            cursor: not-allowed;
          }
          svg {
            path: {
              fill: ${Colors.White100};
            }
          }
        `;
      case "success":
        return css`
          background-color: ${Colors.Green400};
          color: ${Colors.White100};
          border: 1px solid transparent;

          &:disabled {
            opacity: 0.4;
            cursor: not-allowed;
          }
          svg {
            path: {
              fill: ${Colors.White100};
            }
          }
        `;
      case "danger":
        return css`
          background-color: ${Colors.Red400};
          color: ${Colors.White100};
          border: 1px solid transparent;

          &:disabled {
            opacity: 0.4;
            cursor: not-allowed;
          }
          svg {
            path: {
              fill: ${Colors.White100};
            }
          }
        `;
      case "outline":
        return css`
          background-color: ${Colors.White100};
          color: ${Colors.Black100};
          border: 1px solid #cbd2d9;

          &:disabled {
            /* background: rgba(0, 0, 0, 0.75); */
            cursor: not-allowed;
          }
          svg {
            path: {
              fill: ${Colors.Black100};
            }
          }
        `;
      case "clear":
        return css`
          background-color: transparent;
          color: ${Colors.Green100};
          border: none;
          svg {
            path: {
              fill: ${Colors.Green100};
            }
          }
        `;
    }
  }};
`;

export default Button;
