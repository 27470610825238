import { fork, call, take, ActionPattern } from "redux-saga/effects";

// https://github.com/redux-saga/redux-saga/issues/589#issuecomment-361596177

export const takeFirst = (
  pattern: ActionPattern,
  saga: (...args: any[]) => any,
  ...args: any[]
) =>
  fork(function* () {
    while (true) {
      const action: any = yield take(pattern);
      yield call(saga, ...args.concat(action));
    }
  });
