import { PriceItem } from "app/api/price/types";
import createActionType from "../util/createActionType";
import { ReducerStatusType } from "../reducers";

export interface PricesState {
  data: {
    [key: string]: PriceItem;
  };
  dataStatus: ReducerStatusType;
  error: string | null;
}

export const fetchPrices = createActionType("fetch_prices");

export interface FetchPricesAction {
  type: string;
  payload: {
    currency: string;
  };
}
