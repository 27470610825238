import axios, { AxiosError } from "axios";

import api from "app/api";
import { asyncWrap } from "app/utils/helpers";

import session from "app/lib/session";
import storage from "app/lib/storage";
import { TOKEN_STORAGE_KEY } from "app/constants/variables";
import LogUserOut from "app/lib/auth";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

request.interceptors.request.use((config) => {
  const { access_token } = storage.get("tokens") || {};

  const requiresNoToken = config.headers["noToken"];

  const newConfig = { ...config };
  delete newConfig.headers["noToken"];

  if (!access_token || requiresNoToken) return newConfig;

  newConfig.headers = {
    ...newConfig.headers,
    Authorization: `Bearer ${access_token}`,
  };

  return newConfig;
});

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    const { access_token, refresh_token } = storage.get("tokens") || {};

    // if user's token has expired or has been blacklisted
    if (error.response?.status === 401 && access_token) {
      if (!refresh_token) {
        LogUserOut();
        return;
      }

      const [, response] = await asyncWrap(
        api.authService.refreshAccessToken(refresh_token)
      );

      if (!response?.access_token) {
        LogUserOut();
        return;
      }

      storage.set(TOKEN_STORAGE_KEY, response);

      // retry the failed request
      error.config.headers = {
        ...error.config.headers,
        Authorization: `Bearer ${response.access_token}`,
      };

      const [, newResponse] = await asyncWrap(axios.request(error.config));

      if (!Boolean(newResponse)) {
        session.clear();
        storage.clear();
        return;
      }

      return newResponse;
    }
    return Promise.reject(error);
  }
);

export default request;
