import request from "../request";
import {
  FetchMarketPairGraphDataPayload,
  FetchMarketPairGraphDataResponse,
  FetchMarketPairsResponse,
} from "./types";

const fetchMarketPairs = async () => {
  return await request.get<FetchMarketPairsResponse>(`/v1.1/markets/pairs`);
};

const fetchMarketPairGraphData = async (
  payload: FetchMarketPairGraphDataPayload
) => {
  const { period, symbol } = payload;
  return await request.get<FetchMarketPairGraphDataResponse>(
    `/v1/currency/ohlc/${symbol}/ohlc?period=${period}`,
    {
      baseURL: process.env.REACT_APP_API_GATEWAY_URL,
    }
  );
};

const marketService = { fetchMarketPairs, fetchMarketPairGraphData };

export default marketService;
