import * as React from "react";
import { toast } from "react-toastify";
import Notification, { NotificationComponentProps } from "app/components/Notification/index";

/**
 * Pop up notification component
 */
export function notify(
  message: string,
  { variant = "info", closeToast, ...options }: NotificationComponentProps = {}
) {
  return toast(<Notification {...{ message, closeToast, variant }} />, options);
}
